import React, { useState, useEffect } from 'react'
import CustomConfirmDialog from '../../../common/CustomConfirmDialog'
import { useNavigate } from 'react-router-dom'
import { cancelMeeting } from '../../../../api/Counsellor'
import { Session } from '../../SessionsDetails/DetailedSessionPage'
import FeedbackPopup from '../common/feedbackPopup'
import PrepNotesPopup from '../common/prepNotesPopup'
import { determineSessionStatus } from './determineSessionStatus'

interface UserDetailsProps {
  currentSession: Session | null
}

const UserDetails: React.FC<UserDetailsProps> = ({ currentSession }) => {
  const [dialogCallback, setDialogCallback] = useState<(() => void) | null>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)
  const [isPrepNotesOpen, setIsPrepNotesOpen] = useState(false)
  const [currentFeedback, setCurrentFeedback] = useState<string>('')
  const [currentStatus, setCurrentStatus] = useState<string>('')
  const [updateDisabled , setUpdateDisabled] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentSession) {
      // Directly set the current status as fetched from the server
      setCurrentStatus(currentSession.status);

      // Set feedback based on the session status
      if (['Updated_draft', 'Updated'].includes(currentSession.status)) {
        setCurrentFeedback(currentSession.draft_feedback || '');
      } else {
        setCurrentFeedback(currentSession.feedback || '');
      }

      // Disable feedback update for non-upcoming sessions
      setUpdateDisabled(currentSession.status !== 'Upcoming');
    }
  }, [currentSession]);

  const handleCancelSession = (
    counselorEmail: string,
    userEmail: string,
    date: string,
    time: string,
    timestamp: any
  ) => {
    setDialogCallback(() => async () => {
      try {
        const response = await cancelMeeting(
          counselorEmail,
          userEmail,
          date,
          time,
          timestamp
        )
        if (response.message === 'Meeting canceled successfully.') {
          alert('Session cancelled successfully!')
          setCurrentStatus('Cancelled') // Update the status when the session is canceled
          navigate('/counsellor/dashboard')
        } else {
          alert('Failed to cancel the session. Please try again.')
        }
      } catch (error) {
        console.error('Error cancelling session:', error)
        alert(
          'An error occurred while cancelling the session. Please try again later or contact support@skilljourney.in'
        )
      }
      setIsDialogOpen(false);
    })
    setIsDialogOpen(true)
  }

  const handleUpdateOnClick = () => {
    if (updateDisabled) {
      setIsFeedbackOpen(true);
    }
  };

  const handleBack = (email: string) => {
    navigate('/counsellor/dashboard');
  };

  const handleFeedbackUpdate = (newFeedback: string) => {
    setCurrentFeedback(newFeedback);

    if (currentSession) {
      // Update the current status based on feedback action
      setCurrentStatus(
        currentSession.status === 'Completed'
          ? 'Updated_draft'
          : currentSession.status === 'Updated_draft'
          ? 'Updated_draft'
          : 'Updated'
      );
    }
  };
  return (
    <>
      <div className="gap-2 font-sans">
        {currentSession ? (
          <div className="max-w-4xl mx-auto bg-white p-4 rounded-lg shadow-lg border border-gray-200">
            <div
              className={`grid gap-2 mb-2 ${
                currentSession.status === 'Upcoming'
                  ? 'grid-cols-2 md:grid-cols-4'
                  : 'grid-cols-2 md:grid-cols-3'
              }`}
            >
              <button
                onClick={() => handleBack(currentSession.counselor.email)}
                className="px-4 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-md hover:bg-gray-600 transition-colors duration-300 w-full"
              >
                Back to Sessions
              </button>
              <button
                onClick={handleUpdateOnClick}
                className={`px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md transition-colors duration-300 w-full ${updateDisabled ? 'hover:bg-gray-600' : 'opacity-50 cursor-not-allowed'}`}
              >
                Feedback
              </button>
              <button
                onClick={() => setIsPrepNotesOpen(true)}
                className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-gray-600 transition-colors duration-300 w-full"
              >
                Notes
              </button>
              {currentSession.status === 'Upcoming' && (
                <button
                  onClick={() =>
                    handleCancelSession(
                      currentSession.counselor.email,
                      currentSession?.clientName,
                      currentSession.date,
                      currentSession.time,
                      currentSession.timestamp
                    )
                  }
                  className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition-colors duration-300 w-full"
                >
                  Cancel Session
                </button>
              )}
            </div>

            <div className="flex items-center mb-2">
              <img
                src={currentSession.user_info.profileIcon}
                alt={currentSession.clientName}
                className="size-14 md:size-24 rounded-full border-4 border-blue-500 mr-4"
              />
              <div>
                <h3 className="text-xl md:text-2xl font-semibold text-gray-800 mb-1 w-52 md:w-80 truncate">
                  {currentSession.user_info.name || currentSession.clientName}
                </h3>
                <p className="text-gray-600 text-lg">
                  <strong>Date:</strong> {currentSession.date}
                </p>
                <p className="text-gray-600 text-lg">
                  <strong>Time:</strong> {currentSession.time}
                </p>
                <p
                  className={`text-lg ${
                    currentStatus === 'Completed'
                      ? 'text-green-600'
                      : currentStatus === 'Cancelled'
                      ? 'text-red-600'
                      : currentStatus === 'Expired'
                      ? 'text-gray-600'
                      : currentStatus === 'Awaiting_feedback'
                      ? 'text-orange-600'
                      : 'text-yellow-600'
                  }`}
                >
                  <strong>Status:</strong> {currentStatus}
                </p>
              </div>
            </div>

            <div className="bg-gray-50 p-2 rounded-lg shadow-md">
              {['Completed', 'Updated' , 'Updated_draft'].includes(currentStatus) ? (
                <>
                  <h2 className="text-xl font-semibold text-gray-700 mb-4">
                    Counseling Feedback From {currentSession.counselor.name}
                  </h2>
                  <div className="h-fit overflow-y-auto">
                    {currentFeedback ? (
                      <p
                        className="text-gray-600 whitespace-pre-line"
                        dangerouslySetInnerHTML={{ __html: currentFeedback }}
                      />
                    ) : (
                      <p className="text-gray-600 whitespace-pre-line">
                        No feedback available.
                      </p>
                    )}
                  </div>
                  {currentSession.recordingUrl && (
                    <div className="mt-6 text-center">
                      <a
                        href={currentSession.recordingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        View Session Recording
                      </a>
                    </div>
                  )}
                </>
              ) : currentStatus === 'Upcoming' ? (
                <>
                  <h2 className="text-xl font-semibold text-gray-700">
                    Session Status
                  </h2>
                  <p className="text-gray-600">
                    This session is scheduled for {currentSession.date} at{' '}
                    {currentSession.time}. Please prepare any questions or
                    topics you want to discuss.
                  </p>
                  {currentSession.meetingUrl && (
                    <div className="mt-1 text-center">
                      <a
                        href={currentSession.meetingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        Join the Meeting
                      </a>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h2 className="text-xl font-semibold text-gray-700 mb-4">
                    Session Status
                  </h2>
                  <p className="text-gray-600">
                    This session has been {currentStatus}. Please send feedback to the user.
                  </p>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-600">
            <p className="text-xl font-semibold mb-4">Session not found.</p>
            <button
              onClick={() => navigate('/booked-sessions')}
              className="mt-4 px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300"
            >
              Back to Sessions
            </button>
          </div>
        )}
      </div>
      <FeedbackPopup
        currentSession={currentSession}
        isFeedbackOpen={isFeedbackOpen}
        setIsFeedbackOpen={setIsFeedbackOpen}
        onFeedbackUpdate={handleFeedbackUpdate}
        currentFeedback={currentFeedback}
        onStatusUpdate={setCurrentStatus}
        currentStatus={currentStatus}
      />
      <PrepNotesPopup
        currentSession={currentSession}
        isPrepNotesOpen={isPrepNotesOpen}
        setIsPrepNotesOpen={setIsPrepNotesOpen}
      />
      <CustomConfirmDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => {
          if (dialogCallback) {
            dialogCallback();
          }
        }}
        message="Are you sure you want to cancel this session?"
      />
    </>
  )
}

export default UserDetails
