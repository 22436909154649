import axios from 'axios';
import { BackendApi } from "./apiConfig/ApiFormatter";
import { isUserTokenValid } from '../components/common/ValidateToken';

interface UpdateFeedbackPayload {
    user_email?: string;
    counsellor_email?: string;
    date?: string;
    time?: string;
    feedback?: string;
    draft_feedback?: string;
    timestamp?: string;
    status?: string;
    recording_url?: string;  // Optional parameter
}

export const updateFeedback = async (
    navigate: (path: string) => void,
    user_email?: string,
    counsellor_email?: string, // Make sure this is properly populated
    date?: string,
    time?: string,
    feedback?: string,
    draft_feedback?: string,
    timestamp?: string,
    status?: string,
    recording_url: string = ''
  ): Promise<any> => {
    const apiUrl = `${BackendApi()}updateFeedback`;
  
    const payload = {
      user_email,
      counsellor_email,  // Make sure this is passed
      date,
      time,
      feedback,
      draft_feedback,
      timestamp,
      status,
      recording_url,
    };
  
    try {
      const isValid = await isUserTokenValid();
      if (!isValid) {
        navigate('/login');
      }
  
      const idToken = localStorage.getItem('AccessToken');
      const response = await axios.post(apiUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${idToken}`,
        },
      });
  
      return response;
    } catch (error) {
      console.error('Failed to update feedback', error);
      throw error;
    }
  };
  