import React, { useLayoutEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { checkIfCounselor } from '../api/Counsellor';
import LoadingSpinner from '../components/common/LoadingSpinner';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const CounsellorProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const navigate = useNavigate();
  const [isCounselor, setIsCounselor] = useState<boolean | null>(null);
  useLayoutEffect(() => {
    const verifyCounselor = async () => {
      try {
        const response = await checkIfCounselor( navigate );
        setIsCounselor(!!response?.is_counselor);
      } catch (error) {
        console.error("Error checking if counselor:", error);
        setIsCounselor(false);
      }
    };

    verifyCounselor();
  }, [navigate]);

  if (isCounselor === null) return <LoadingSpinner />;
  if (isCounselor) localStorage.setItem("userLevel", 'Counselor');

  return isCounselor ? element : <Navigate to="*" />;
};

export default CounsellorProtectedRoute;
