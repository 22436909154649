import React, { useState, useEffect } from 'react';
import Journey from "../assets/img/journey.png";
import CreateNavbar from '../components/Navbar/Navbar';
import { CreateUser } from '../api/CreateUser';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import LoadingSpinner from '../components/common/LoadingSpinner';
import Footer from '../components/footer/Footer'
import Transform from '../components/careerPath/Transform';
import Passion from '../components/careerSolutions/Passion';
import { isUserTokenValid } from '../components/common/ValidateToken';
import { updateSectionData } from '../components/common/StorageUtil';
import { HowItWorks } from '../components/common/popup/HowItWorks';
import CounselorCarousel from '../components/common/counsellor/counsellorCards';
const Home: React.FC = () => {
    const navigate = useNavigate();
    const [isCreateJourneyDisabled, setIsCreateJourneyDisabled] = useState(false);
    const [isViewJourneyDisabled, setIsViewJourneyDisabled] = useState(false);
    const [createJourneyText, setCreateJourneyText] = useState("CREATE JOURNEY");
    const [loading, setLoading] = useState<boolean>(true); // Loading state

    useEffect(() => {
        const initialize = async () => {
            const isValid = await isUserTokenValid();
            if (isValid) {

                const emailIdPresent = localStorage.getItem('emailIdPresent') === 'true';
                const editAllowed = localStorage.getItem('editAllowed') === 'true';
                const journeyExist = localStorage.getItem('journeyExist') === 'true';
                
                // Update button text based on emailIdPresent
                setCreateJourneyText( journeyExist  ? "UPDATE JOURNEY" : "CREATE JOURNEY");
               

                // Disable the button if emailIdPresent is true and editAllowed is false
                if (emailIdPresent && !editAllowed) {
                    setIsCreateJourneyDisabled(true);
                }
                if (!journeyExist) {
                    setIsViewJourneyDisabled(true);
                }
                setLoading(false); // Stop loading once initialization is complete
            }
            else {
                navigate('/');
            }
        };
        initialize();
    }, []);

    const CreateJourney = () => {
        if (!isCreateJourneyDisabled) {
            const useremail = localStorage.getItem("Email");
            CreateUser();
            updateSectionData(useremail, "activeSectionIndex", 0);
            navigate("/questionnaire");
        }
    };


    const ViewJourney = () => {
        if (!isViewJourneyDisabled) {
            localStorage.getItem("userLevel") === 'Premium'
                ? localStorage.getItem("premiumJourneyStatus") === 'published'
                    ? navigate("/premiumjourney")
                    : navigate("/premiumjourneyloading")
                : navigate("/myjourney");
        }
    };

    if (loading) {
        return <LoadingSpinner />; // Show spinner while loading
    }
    return (
        <div className="flex overflow-y-auto flex-col">
            <CreateNavbar page='home' />
            <div className='flex-grow relative grid sm:grid-cols-1 md:grid-cols-2 bg-background w-screen'>
                <div className='container sm:mt-10 lg:mt-15'>
                    <div className='text-custom-orange text-xl lg:text-5xl font-bold font-sans whitespace-nowrap'>
                        Welcome to SkillJourney
                    </div>

                    <div className='text-sky-600 font-sans text-xl lg:text-4xl md:py-2 font-bold whitespace-nowrap'>
                        Your Career Success Companion
                    </div>
                    <div className='hidden md:block text-black font-sans text-base font-normal lg:whitespace-nowrap py-3'>
                        Your career path is as unique as you are! SkillJourney provides personalized <br /> insights and actionable steps to help achieve your goals.<br />
                        Here’s what you will get:
                        <ul className='list-disc ml-6 pt-3'>
                            <li>Tailored Career Journeys</li>
                            <li>Advanced AI and Data-Driven Insights</li>
                            <li>Tracking and refining of your journey</li>
                            <li>Learning and Upskilling resources</li>
                            <li>Personalized counselling and much more</li>
                        </ul>
                    </div>
                    <div className='md:text-base text-sm font-sans font-normal text-black md:py-0 py-3 '>
                        <div className='block md:hidden'>
                            Your career path is as unique as you are! SkillJourney provides personalized insights and actionable steps to help achieve your goals.
                        </div>
                    </div>
                    <HowItWorks link='gPKGjrQydkk' page='home' />
                    <div className='hidden md:flex md:space-x-7 mt-80 md:-mt-2'>
                        <Tooltip title={isCreateJourneyDisabled ? 'You have Exceeded the Edit limit' : ''}>
                            <button
                                className={`flex items-center  w-full font-sans px-10 py-2.5 my-5 text-base font-bold transition-colors duration-300 transform md:w-56 md:h-14 text-white focus:outline-none rounded-full shadow-homepage whitespace-nowrap ${isCreateJourneyDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-custom-orange'} rounded-full shadow-homepage`}
                                onClick={CreateJourney}
                                disabled={isCreateJourneyDisabled}>
                                {createJourneyText}
                            </button>
                        </Tooltip>
                        <Tooltip title={isViewJourneyDisabled ? 'Your Journey is not yet created' : ''}>
                            <button
                                className={`flex items-center justify-between w-full font-sans px-10 py-2.5 my-5 text-base font-bold transition-colors duration-300 transform md:w-56 md:h-14 focus:outline-none rounded-full shadow-homepage whitespace-nowrap ${isViewJourneyDisabled ? 'bg-gray-400 text-white cursor-not-allowed' : 'bg-sky-600 text-white'}`}
                                onClick={ViewJourney}
                                disabled={isViewJourneyDisabled}
                            >
                                <span className="flex-grow text-center">VIEW MY JOURNEY</span>

                            </button>
                        </Tooltip>
                    </div>

                </div>
                <div className='m-auto relative md:-mt-5'>
                    <div className="flex justify-end">
                        <img src={Journey} alt="Journey" className='md:w-[610px] md:h-[600px] md:pt-16 w-auto h-auto z-0' />
                    </div>

                    <div className='md:hidden flex flex-col items-center py-1 pb-5'>
                        <div className="flex flex-row w-full justify-center space-x-4 px-6 "> {/* Added padding here */}
                            <Tooltip title={isCreateJourneyDisabled ? 'You have Exceeded the Edit limit' : ''}>
                                <button
                                    className={`flex-1 p-3 py-2.5 font-sans text-xs font-bold tracking-wider text-white transition-colors duration-300 transform focus:outline-none ${isCreateJourneyDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-custom-orange'} rounded-full shadow-homepage`}
                                    onClick={CreateJourney}
                                    disabled={isCreateJourneyDisabled}>
                                    {createJourneyText}
                                </button>
                            </Tooltip>
                            <Tooltip title={isViewJourneyDisabled ? 'Your Journey is not yet created' : ''}>
                                <button
                                    className={`flex-1  p-2 py-2.5 font-sans text-xs font-bold tracking-wider text-white transition-colors duration-300 transform focus:outline-none ${isViewJourneyDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-sky-600'} rounded-full shadow-homepage`}
                                    onClick={ViewJourney}
                                    disabled={isViewJourneyDisabled}>
                                    <span className="flex-grow text-center">VIEW MY JOURNEY</span>

                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>

            </div>
            <>

                <Passion />
                <div className='md:container md:mx-auto'>
                    <Transform />
                </div>

                <CounselorCarousel />

                <Footer />
            </>
        </div>
    );
};

export default Home;
