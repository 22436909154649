import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateNavbar from '../../Navbar/Navbar';
import { cancelMeeting } from '../../../api/Counsellor';
import CustomConfirmDialog from '../../common/CustomConfirmDialog';
import { UserInfo } from '../../Counsellor/counsellorDashboard';

interface Counselor {
  name: string;
  email: string;
  contact: number;
  profileUrl: string;
  bio: string;
  address: string;
  specialty?: string; // Optional
  rating: number;
  timestamp?: any;
}

export interface Session {
  id: string; // meeting_id is a string
  clientName: string;
  counselor: Counselor;
  user_info: UserInfo;
  date: string;
  time: string;
  status: 'Upcoming' | 'Completed' | 'Cancelled' | 'Updated' | 'Updated_draft' | 'Awaiting_feedback' | 'Expired';
  feedback?: string; // Optional feedback field
  meetingUrl?: string; // Optional meeting URL for upcoming sessions
  recordingUrl?: string; // Optional recording URL for completed sessions
  timestamp?: any;
  draft_feedback?: string;
}

const DetailedSessionPage: React.FC = () => {
  const location = useLocation();
  const session = location.state?.session as Session;
  const navigate = useNavigate();
  const [currentSession, setCurrentSession] = useState<Session | null>(session);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogCallback, setDialogCallback] = useState<(() => void) | null>(null);

  useEffect(() => { 
    // Scroll to the top of the page on load
    window.scrollTo(0, 0);
  }, []);

  const handleCancelSession = (counselorEmail: string, date: string, time: string, timestamp: any) => {
    setDialogCallback(() => async () => {
      try {
        const userEmail: any = localStorage.getItem('Email');
        const response = await cancelMeeting(counselorEmail, userEmail, date, time, timestamp);
        if (response.message === "Meeting canceled successfully.") {
          alert('Session cancelled successfully!');
          setCurrentSession(prev => prev ? { ...prev, status: 'Cancelled' } : prev); // Update status in current session
          navigate('/booked-sessions');
        } else {
          alert('Failed to cancel the session. Please try again.');
        }
      } catch (error) {
        console.error('Error cancelling session:', error);
        alert('An error occurred while cancelling the session. Please try again later.');
      }
      setIsDialogOpen(false);
    });

    setIsDialogOpen(true);
  };

  return (
    <>
      <CreateNavbar page='home' />
      <div className="bg-gray-100 min-h-screen p-4 md:p-6 lg:p-8 font-sans">
        {currentSession ? (
          <div className="max-w-4xl mx-auto bg-white p-6 md:p-8 lg:p-10 rounded-lg shadow-lg border border-gray-200">
            <div className="flex items-center mb-6">
              <img
                src={currentSession.counselor.profileUrl}
                alt={currentSession.counselor.name}
                className="w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full border-4 border-blue-500 mr-4"
              />
              <div>
                <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-gray-800 mb-2">{currentSession.counselor.name}</h1>
                <p className="text-gray-600 text-lg mb-1"><strong>Specialty:</strong> {currentSession.counselor.specialty || 'N/A'}</p>
                <p className="text-gray-600 text-lg mb-1"><strong>Date:</strong> {currentSession.date}</p>
                <p className="text-gray-600 text-lg mb-1"><strong>Time:</strong> {currentSession.time}</p>
                <p className={`text-lg font-semibold ${currentSession.status === 'Completed' || currentSession.status === 'Updated_draft' ? 'text-green-600' : currentSession.status === 'Cancelled' ? 'text-red-600' : currentSession.status === 'Awaiting_feedback' ? 'text-orange-600' : currentSession.status === 'Expired' ? 'text-gray-500' : 'text-yellow-600'}`}>
                  <strong>Status:</strong> {currentSession.status === 'Cancelled' ? 'Cancelled' : currentSession.status === 'Updated' ? 'Awaiting_feedback' : currentSession.status === 'Updated_draft' ? 'Review in Progress' : currentSession.status}
                </p>
              </div>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg shadow-md mt-6">
              {currentSession.status === 'Completed' ||  currentSession.status === 'Updated_draft' ? (
                <>
                  <h2 className="text-xl font-semibold text-gray-700 mb-4">Counseling Feedback From {currentSession.counselor.name}</h2>
                  <div className="max-h-96 overflow-y-auto">
                    {currentSession?.feedback
                      ? <p className="text-gray-600 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: currentSession?.feedback }} />
                      : <p className="text-gray-600 whitespace-pre-line">No feedback available.</p>}
                  </div>
                  {currentSession.recordingUrl && (
                    <div className="mt-6 text-center">
                      <a href={currentSession.recordingUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        View Session Recording
                      </a>
                    </div>
                  )}
                </>
              ) : currentSession.status === 'Awaiting_feedback' || currentSession.status === 'Updated' ? (
                <p className="text-gray-600">Awaiting Feedback from a Counsellor</p>
              ) : currentSession.status === 'Expired' ? (
                <p className="text-gray-600">This session is Expired. Please contact support@skilljourney.in for more details.</p>
              ) : currentSession.status === 'Upcoming'  ? (
                <>
                  <h2 className="text-xl font-semibold text-gray-700 mb-4">Session Status</h2>
                  <p className="text-gray-600">This session is scheduled for {currentSession.date} at {currentSession.time}. Please prepare any questions or topics you want to discuss.</p>
                  {currentSession.meetingUrl && (
                    <div className="mt-6 text-center">
                      <a href={currentSession.meetingUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        Join the Meeting
                      </a>
                    </div>
                  )}
                </>
              ) : (
                <p className="text-gray-600">This session has been cancelled. If you need assistance, please contact support.</p>
              )}
            </div>

            <div className="flex justify-between mt-8">
              <button
                onClick={() => navigate('/booked-sessions')}
                className="px-4 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-md hover:bg-gray-600 transition-colors duration-300"
              >
                Back to Sessions
              </button>
              {currentSession.status === 'Upcoming' && (
                <button
                  onClick={() => handleCancelSession(currentSession.counselor.email, currentSession.date, currentSession.time, currentSession.timestamp)}
                  className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition-colors duration-300"
                >
                  Cancel Session
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-600">
            <p className="text-xl font-semibold mb-4">Session not found.</p>
            <button
              onClick={() => navigate('/booked-sessions')}
              className="mt-4 px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300"
            >
              Back to Sessions
            </button>
          </div>
        )}
      </div>
      <CustomConfirmDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={() => {
          if (dialogCallback) {
            dialogCallback();
          }
        }}
        message="Are you sure you want to cancel this session?"
      />
    </>
  );
};

export default DetailedSessionPage;
