import { BackendApi } from './apiConfig/ApiFormatter';

// Define types for response and payloads
interface PaymentResponse {
    razorpay_order_id: string;
    razorpay_payment_id: string;
    razorpay_signature: string;
}

export const InitiatePayment = async (
    amount:Number
): Promise<any> => {
    const apiUrl = BackendApi() + 'initiatePayment';
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage
    const payload={"amount":Number(amount)}
    try {
        const response:any = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            },
            body: JSON.stringify(payload)
        });
        const data: any = await response.json();
        if (!data || data.data.length === 0) {
            throw new Error('No Payment Details Found');
        }
        return data;
    } catch (error) {
        console.error('Error generating order ID:', error);
        throw error;  // Optionally, rethrow the error
    }
};

export const verifyAndSavePaymentCredential = async (
    response: PaymentResponse,
    formattedTimestamp: string
): Promise<any> => {
    const apiUrl = BackendApi() + 'verifyAndSavePaymentCredential';
    const payload = {
        order_id: response.razorpay_order_id,
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_signature: response.razorpay_signature,
        Payment_success_timestamp: formattedTimestamp,
    };
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage
    try {
        const response = await fetch(apiUrl, {
            method: 'PUT', // HTTP method
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${idToken}`, // Bearer token for authorization
            },
            body: JSON.stringify(payload) // Convert payload to JSON
        });
        
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            // Handle errors here, e.g., throw an error or return a specific response
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        return response;        
    } catch (error) {
        console.error('Error verifying and saving payment credential:', error);
        throw error;  // rethrow the error
    }
};

export const paymentInfoByOrderId = async (
    response: PaymentResponse,
    formattedTimestamp: string
): Promise<any> => {
    const apiUrl = BackendApi() + 'paymentInfoByOrderId';
    const payload = {
        order_id: response.razorpay_order_id,
    };
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage
    try {
        const response = await fetch(apiUrl, {
            method: 'POST', // HTTP method
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${idToken}`, // Bearer token for authorization
            },
            body: JSON.stringify(payload) // Convert payload to JSON
        });
        
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            // Handle errors here, e.g., throw an error or return a specific response
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: any = await response.json();
        
        return data;        
    } catch (error) {
        console.error('Error verifying and saving payment credential:', error);
        throw error;  // rethrow the error
    }
};

export const dropPaymentDetails = async (): Promise<any> => {
    const apiUrl = BackendApi() + 'dropPaymentDetails';
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

    try {
        const response = await fetch(apiUrl, {
            method: 'DELETE', // HTTP method
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${idToken}`, // Bearer token for authorization
            }
        });
        
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            // Handle errors here, e.g., throw an error or return a specific response
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        // Parse the JSON response
        const data: any = await response.json();
    return data;        
    } catch (error) {
        console.error('Error deleting Payment details:', error);
        throw error;  // rethrow the error
    }
};
