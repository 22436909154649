import React from 'react';
import ComingSoon from "../assets/img/ComingSoon/Comingsoon.svg";
import CreateNavbar from '../components/Navbar/Navbar';

const Comingsoon: React.FC = () => {
    return (
        <div className="relative min-h-screen flex flex-col">
        <CreateNavbar page='contact' />
        <div className="flex-1 flex flex-col justify-center items-center px-auto md:px-auto py-auto md:py-auto">

            <img src={ComingSoon} alt="Coming Soon" className="w-3/4 md:w-1/2 h-auto py-2" />
            <p className="text-center font-proxima text-2xl md:text-5xl font-bold text-custom-blue tracking-tight mt-4">
                C O M I N G&nbsp;&nbsp;&nbsp;&nbsp;S O O N <span className="text-xl md:text-3xl">.&nbsp;&nbsp;.&nbsp;&nbsp;.</span>
            </p>

        </div>
    </div>
    );
};

export default Comingsoon;
