import React from 'react';
import { UserInfo } from '../../../Counsellor/counsellorDashboard';

interface IframeCompProps {
    user_info?: UserInfo;
    className?: string;
    endpoint?: string;
}

const IframeComp: React.FC<IframeCompProps> = ({ user_info, className, endpoint }) => {

    // Construct the iframe URL and append the clientId as a query parameter if it exists
    const baseUrl = window.location.href.replace(/(https?:\/\/[^\/]+).*/, '$1');
    const iframeSrc = `${baseUrl}${endpoint}?user_info=${ JSON.stringify(user_info)}`;

    return (
            <iframe
                src={iframeSrc}
                title="Embedded Page"
                className={className}
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
    );
};

export default IframeComp;
