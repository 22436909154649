import React, { useState } from 'react';
import play from '../../../assets/img/play.png'
import { isMobile } from 'react-device-detect';

interface HowItWorksProps {
    link: string;
    page?: string;
}

export const HowItWorks: React.FC<HowItWorksProps> = ({ link, page }) => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => { setIsOpen(!isOpen) };

    return (
        <>
            {/* Button to open the popup */}
            <div className={`flex ${page !== 'home' ? 'py-4' : ` ${isMobile ?'items-center justify-center' : 'py-5 pl-1 '}`} `}>

                <button onClick={togglePopup}
                    className="flex items-center text-black text-xs md:text-base font-semibold font-sans w-auto whitespace-nowrap ">
                    <img src={play} alt="Logo" className="h-6 w-6 md:mr-4 mr-2" />
                    Watch how it works
                </button>
            </div>

            {/* Popup box */}
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-lg">
                        {/* Close button */}
                        <button
                            onClick={togglePopup}
                            className="flex justify-end w-full text-gray-500 hover:text-gray-700">
                            X
                        </button>

                        {/* YouTube video */}
                        <div className="">
                            <iframe
                                width={isMobile ? '300' : "560"}
                                height="315"
                                src={`https://www.youtube.com/embed/${link}?autoplay=1`}
                                title="YouTube video player"
                                frameBorder="0"
                                data-allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
