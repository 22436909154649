import React, { useCallback, useEffect, useState } from 'react';
import CreateNavbar from '../components/Navbar/Navbar';
import PremiumJourneyBody from '../components/PremiumJourneys/PremiumJourneyBody';
import '../components/PremiumJourneys/PremiumJourneyBody.css'
import { FetchPremiumJourney } from '../api/FetchHtmlJourney';
import { isUserTokenValid } from '../components/common/ValidateToken';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { isMobile } from 'react-device-detect';
import LeftPremiumSidebar from '../components/sidebar/LeftPremiumSidebar';
import RightPremiumSidebar from '../components/sidebar/RightPremiumSidebar';
import useIsIframe from '../components/CounsellorUser/CounsellorsClientPortFolio/common/IsIframe';
import JourneyInsights from '../components/common/error/JourneyInsights';
import { PremiumJourneyStatusCheck } from '../api/JourneyStatusCheck';

const PremiumJourney: React.FC = () => {
  const [premiumJourney, setPremiumJourney] = useState<any>({});
  const [isResponsive, setIsResponsive] = useState<boolean>(false);
  const navigate = useNavigate();
  const isIframe = useIsIframe();
  const [loading, setLoading] = useState<boolean>(true);
  const userLevel = localStorage.getItem("userLevel");

  const handleResize = useCallback(() => {
    setIsResponsive(window.innerWidth < 768);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);


  const fetchPremiumJourney = async () => {
    if (!(await isUserTokenValid())) {
      navigate('/login', { state: { page: "pcj" } });
      return;
    }
    const journeyCheck = await PremiumJourneyStatusCheck();
    if (!userLevel?.includes('Counselor') && journeyCheck && [ 'failed','completed','initiated'].includes(journeyCheck.journey_status)){
      navigate('/premiumjourneyloading');
    }
    const journeyData = await FetchPremiumJourney();
    setPremiumJourney(journeyData);
    setLoading(false)
  }

  useEffect(() => { fetchPremiumJourney(); }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!['Premium', 'Counselor'].includes(localStorage.getItem("userLevel") || '')) navigate('*', { state: { redirect: 'Premium-journey-page-unauthorised' } });

  const isJourneyAvailable = premiumJourney && premiumJourney.career_premium_journey;
  if (!isJourneyAvailable && localStorage.getItem("userLevel")?.includes('Counselor')) return <JourneyInsights message='Premium' />;

  return (
    <div className="h-screen font-sans">
      {!isIframe && <CreateNavbar page='premiumJourney' />}
      <div className={`flex flex-row w-full h-full ${!isIframe && 'pt-4'} md:pb-5 md:pt-20 justify-evenly`}>
        {!isIframe && <LeftPremiumSidebar visible={!isResponsive} />}
        <PremiumJourneyBody premiumJourney={premiumJourney.career_premium_journey} isIframe={isIframe} />
        {!isMobile && !isIframe && <RightPremiumSidebar premiumJourney={premiumJourney.career_premium_journey} />}
      </div>
    </div>
  );
};

export default PremiumJourney;
