import { getQueryParam } from "../../src/components/CounsellorUser/CounsellorsClientPortFolio/common/util";
import { BackendApi } from "./apiConfig/ApiFormatter";



export const FetechUserResponses = async (): Promise<any[]> => {
    const apiUrl = BackendApi() + "readUserQuestionnaire";
    const user_info = JSON.parse(getQueryParam('user_info') as string) || null;

    try {
        const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': `${idToken}` 
            },
            body: JSON.stringify({ "email": user_info ? user_info.email : localStorage.getItem("Email") })
        });

        if (!response.ok) {
            console.log('Error fetching user questionnaire');
        }

        const questionWithAnswers = await response.json();
        if(questionWithAnswers.data===null)
            return []
        return questionWithAnswers.data;
    } catch (error) {
        console.error('Error fetching user questionnaire:', error);
        throw error; // Rethrow error to handle it in the component
    }
};
