import React from 'react';

export const CheckBox: React.FC<any> = ({ field, draftData, setDraftData, isIframe }) => {
  return (
    <fieldset>
      <legend className="block text-base sm:text-lg text-description-text">
        {field.label} {(field.validations?.maxSelect !== null)
          ? (field.validations?.maxSelect === 1)
            ? '(Select one)'
            : `(Select up to ${field.validations?.maxSelect})`
          : "(Select all that apply)"}
        {field.required && <sup className="text-red-600 font-bold text-base">*</sup>}
      </legend>
      {field.options?.map((option: any) => (
        <div key={option.value} className="flex flex-col w-full sm:flex-row items-start sm:items-center gap-1 py-1">
          <div className="flex items-center w-full">
            <input
              type="checkbox"
              id={option.value}
              name={field.name}
              value={option.value}
              checked={(Array.isArray(draftData[field.id]) && draftData[field.id]?.some((item: string) => item.startsWith(option.value))) || false}
              onChange={isIframe ? undefined : (e) => { // Disable change if in iframe
                const selectUpto = field.validations?.maxSelect !== null
                  ? Number(field.validations?.maxSelect)
                  : null;
                const currentValues: string[] = draftData[field.id] || [];

                // Check if the current length is smaller than selectUpto before adding new value
                if (e.target.checked && (selectUpto === null || currentValues.length < selectUpto)) {
                  if (option.value === "other") {
                    const otherValue = "other-" + (draftData[field.id]?.find((item: string) => item.startsWith("other-"))?.slice(6) || "");
                    setDraftData((prev: any) => ({
                      ...prev,
                      [field.id]: [...currentValues.filter(item => !item.startsWith("other-")), otherValue]
                    }));
                  } else {
                    setDraftData((prev: any) => ({
                      ...prev,
                      [field.id]: [...currentValues, option.value]
                    }));
                  }
                } else if (!e.target.checked) {
                  if (option.value === "other") {
                    setDraftData((prev: any) => ({
                      ...prev,
                      [field.id]: currentValues.filter(item => !item.startsWith("other-"))
                    }));
                  } else {
                    setDraftData((prev: any) => ({
                      ...prev,
                      [field.id]: currentValues.filter((v: string) => v !== option.value)
                    }));
                  }
                } else {
                  alert((field.validations?.maxSelect === 1)
                    ? 'You can select only one. Deselect the current selection and then select another one.'
                    : `You can select only up to ${field.validations?.maxSelect} options.`);
                }
              }}
              disabled={isIframe} // Disable checkbox if in iframe
            />
            <label htmlFor={option.value} className="pl-2 cursor-pointer">{option.label}</label>
            {option.value === "other" && (
              <div className="w-full">
                <input
                  className={`border-b-2 border-zinc-200 focus:outline-none focus:shadow-outline font-medium text-sm sm:text-base w-full ${isIframe ? 'bg-gray-200 cursor-not-allowed' : ''}`} // Adjust styles if in iframe
                  type="text"
                  value={Array.isArray(draftData[field.id])
                    ? draftData[field.id].find((item: string) => item.startsWith("other-"))?.slice(6) || ""
                    : ""}
                  onChange={isIframe ? undefined : (e) => { // Disable change if in iframe
                    const selectUpto = field.validations?.maxSelect !== null
                      ? Number(field.validations?.maxSelect)
                      : null;
                    const otherValue = "other-" + e.target.value;
                    // Accept other in 3 scenarios: 1) "selectUpto" null, 2) draftData[field.id].length smaller than selectUpto, 3) length exact equal and other value already selected
                    if (selectUpto === null || draftData[field.id].length < selectUpto
                      || (draftData[field.id].length === selectUpto
                        && draftData[field.id].find((item: string) => item.startsWith("other-")))) {
                      setDraftData((prev: any) => ({
                        ...prev,
                        [field.id]: (prev[field.id] || [])
                          .filter((item: string) => !item.startsWith("other-"))
                          .concat(otherValue)
                      }));
                    } else {
                      alert((field.validations?.maxSelect === 1)
                        ? 'You can select only one. Deselect the current selection and then select another one.'
                        : `You can select only up to ${field.validations?.maxSelect} options.`);
                    }
                  }}
                  placeholder="Please specify"
                  disabled={isIframe} // Disable input if in iframe
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </fieldset>
  );
}
