import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import mainLogo from '../../assets/img/logo.png';
import { InitiatePayment, paymentInfoByOrderId, verifyAndSavePaymentCredential } from '../../api/InitiatePayment';
import { useNavigate } from 'react-router-dom';
import { SnackbarComponent } from '../Snackbar/SnackbarComponent';
import { isUserTokenValid } from './ValidateToken';


const REDIRECT_PATH = '/premiumjourneyloading';
// Define types for responses and payloads
interface PaymentResponse {
  message: string;
  data: {
    razorpay_key: string;
    amount: number;
    currency: string;
    order_id: string;
    status: string;
    email: string;
    name: string;
    contact: string;
  };
}

interface RazorpayButtonProps{
  buttonText: string,
  amount:any
}

export const RazorpayButton: React.FC<RazorpayButtonProps> = ({buttonText,amount}) => {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [backendMessage, setBackendMessage] = useState<string>('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [originalPage, setOriginalPage] = useState<string>('/');

  useEffect(() => {
    // Store the original page URL from where the payment was initiated
    setOriginalPage(window.location.pathname);
  }, []);

  const paymentResponseHandler = async (response: any) => {
    const now = new Date();
    const formattedTimestamp = now.toLocaleString();
    const isValid = await isUserTokenValid();
    try {
      const re:any = await paymentInfoByOrderId(response, formattedTimestamp);
      if (re.data.status==="paid") {
        localStorage.setItem('userLevel', 'Premium')
        navigate(REDIRECT_PATH); // Navigate
      } else  {
        setBackendMessage(re.data.status);
        setIsSnackbarOpen(true);
        setTimeout(() => {
          navigate(originalPage); // Navigate back to the original page     
        }, 2000); // Give some time for the snackbar to show
      }
    } catch (error) {
      console.error('Error verifying and saving payment credential:', error);
      setIsSnackbarOpen(true);
      if(!isValid)
        navigate('/'); // Navigate back to the Home page
      else
        navigate(originalPage);
      setBackendMessage('An error occurred. Please try again.');
      setTimeout(() => {
        if(!isValid)
          navigate('/'); // Navigate back to the Home page
        else
          navigate(originalPage);
      }, 2000); // Give some time for the snackbar to show
    }finally{
      setIsDisabled(false);
    }
  };

  const paymentHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsDisabled(true);
    const isValid = await isUserTokenValid();
    try {
      
      const response: PaymentResponse = await InitiatePayment(amount);
     
      if (response.data.status === 'created') {
        const response_send={razorpay_order_id: response.data.order_id,razorpay_payment_id: "",razorpay_signature: ""}
        verifyAndSavePaymentCredential(response_send,"");
        const options: any = {
          key: response.data.razorpay_key, // Your Razorpay key
          amount: response.data.amount * 100, // Convert amount to paise if in INR
          currency: response.data.currency, // Currency code (e.g., "INR")
          name: 'SkillJourney', // Your company or app name
          description: 'Primium Journey Subscription', // Description of the transaction
          image: mainLogo, // URL or path to your company logo
          order_id: response.data.order_id, // Unique order ID from the server
          handler: paymentResponseHandler, // Function to handle payment response
          prefill: {
            name: response.data.name, // Customer name
            email: response.data.email, // Customer email
            contact: response.data.contact // Customer contact number
          },
          notes: {
            address: 'Skilljourney office' // Additional notes for the transaction
          },
          theme: {
            color: '#3399cc' // Custom color for the Razorpay widget
          }
        };

        const rzp1 = new (window as any).Razorpay(options);
        rzp1.open();
      } else if (response.data.status === 'paid') {
        setIsSnackbarOpen(true);
        setBackendMessage(response.data.status);
        setTimeout(() => {
          navigate(REDIRECT_PATH); // Navigate
        }, 1000); // Give some time for the snackbar to show
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      setIsSnackbarOpen(true);
      setBackendMessage('An error occurred. Please try again.');
      setTimeout(() => {
        if(!isValid)
          navigate('/'); // Navigate back to the Home page
        else
          navigate(originalPage);      }, 2000); // Give some time for the snackbar to show
    }finally{
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    script.onerror = () => { throw new Error('Failed to Load Razorpay Script'); };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Button
        onClick={paymentHandler}
        variant="contained"
        color="primary"
        disabled={isDisabled}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#0778B6',
          fontWeight: '500',
          color: 'white',
          borderRadius: '8px',
          alignItems: 'center',
          fontSize: { xs: '0.875rem', sm: '0.8rem' },
          paddingY: 1.5,
          paddingX: { xs: 10, sm: 6 },
          whiteSpace: 'nowrap', // Added to ensure single line
          '&:hover': {
            backgroundColor: '#0778b6',
          },
        }}
      >
        {buttonText}
      </Button>
      <SnackbarComponent isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} backendMessage={backendMessage} />
    </div>
  );
};
