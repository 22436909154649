import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import IconButton from '@mui/material/IconButton'

export interface Job {
  company: string
  job_id: string
  position: string
  role: string
  location: string
  experience: string
  details: string
  responsibilities: string
  qualifications: string
  must_have_skills: string
  job_url: string
}

const ExpandableText: React.FC<{ text: string; label?: string }> = ({
  text,
  label,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isLongText = text.length > 150

  if (!isLongText) {
    return (
      <p className="text-md text-gray-600">
        {label && (
          <span className="font-semibold text-gray-800">{label}: </span>
        )}
        {text}
      </p>
    )
  }

  return (
    <div className="text-md text-gray-600 flex items-start gap-1">
      <div className="flex-grow">
        {label && (
          <span className="font-semibold text-gray-800">{label}: </span>
        )}
        {isExpanded ? text : text.slice(0, 270) + '...'}
      </div>
      <IconButton
        onClick={() => setIsExpanded(!isExpanded)}
        size="small"
        className="mt-0.5"
      >
        {isExpanded ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        )}
      </IconButton>
    </div>
  )
}

const JobCard: React.FC<{ job: Job }> = ({ job }) => {
  const hasContent = (value: any) => {
    return (
      typeof value === 'string' &&
      value.trim() !== '' &&
      value !== 'Not Available' &&
      value !== 'Not Specified' &&
      value !== 'Not available' &&
      value !== 'not available' &&
      value !== 'Not specified' &&
      value !== 'not specified'
    )
  }

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md border border-gray-200 flex flex-col gap-3 md:gap-4">
      {/* Company Logo and Basic Info */}
      <div className="flex flex-row items-start md:items-center gap-2 md:gap-4">
        {/* <img
    src={`/jobs/${job.company.toLowerCase()}.svg`}
    alt={`${job.company} logo`}
    className="h-6 md:h-8 min-w-[30px] md:min-w-[40px]"
  /> */}
        <div className="flex flex-col md:flex-row items-start md:items-center gap-1 md:gap-2 w-full">
          <div className="flex flex-row items-center gap-1">
            <span className="text-sm md:text-base font-medium text-gray-800">
              {job.company}
            </span>
            {hasContent(job.location) && (
              <span className="text-xs md:text-sm text-gray-500 flex items-center gap-1">
                <LocationOnIcon fontSize="small" />
                {job.location}
              </span>
            )}
          </div>
          {hasContent(job['job_id']) && (
            <span className="text-xs md:text-sm text-gray-500 mt-1 md:mt-0">
              ID: {job['job_id']}
            </span>
          )}
        </div>
      </div>

      <div className="border-t border-gray-200 w-full"></div>

      {/* Position */}
      <h3 className="text-lg md:text-xl font-semibold text-gray-800">
        {job.position}
      </h3>

      {/* Expandable Sections */}
      {hasContent(job.responsibilities) && (
        <ExpandableText text={job.responsibilities} label="Responsibilities" />
      )}

      {!hasContent(job.responsibilities) && hasContent(job.details) && (
        <ExpandableText text={job.details} label="Details" />
      )}

      {hasContent(job.experience) && (
        <ExpandableText text={job.experience} label="Experience" />
      )}

      {hasContent(job.qualifications) && (
        <ExpandableText text={job.qualifications} label="Qualifications" />
      )}

      {/* Role, Skills and Apply Button */}
      <div className="flex flex-row justify-between items-start md:items-center gap-2 mt-2">
        <div className="flex flex-col gap-2">
          {hasContent(job.role) && (
            <ExpandableText text={job.role} label="Category" />
          )}
          {hasContent(job['must_have_skills']) && (
            <ExpandableText text={job['must_have_skills']} label="Skills" />
          )}
        </div>
        <a
          href={job['job_url'] || '#'}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#0778B6] text-white px-3 py-2 rounded hover:bg-blue-700 transition duration-300 text-sm md:text-base whitespace-nowrap"
        >
          APPLY NOW
        </a>
      </div>
    </div>
  )
}

export default JobCard
