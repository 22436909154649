import { BackendApi } from './apiConfig/ApiFormatter';


export const verifyCertificate = async (
  id : any
): Promise<any> => {
  const apiUrl = BackendApi() + 'verify/certificate';
  const payload={"id":id}
  try {
      const response = await fetch(apiUrl, {
          method: 'POST', // HTTP method
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
      });

      const data: any = await response.json();
      
      return data;        
  } catch (error) {
    console.error('Certificate Validation Failed', error);
      throw error;  // rethrow the error
  }
};