import React from 'react';
import verificationIcon from '../../../assets/img/verification-icon.svg';
import './CertificatePage.css';

const CertificateMobile: React.FC<any> = ({data}) => {
    const profileName =data.profileName;
    const email = data.email;
    const skills:[] = data.skills;
    const certificateDetails =data.certificateDetails;
    const issuedby=data.issuedby;
    const authorizedBy=data.authorizedBy;
    const certificate=data.certificate;
    const photoURL=data.photoURL
  return (
    <div className="flex flex-col items-center p-5">
      <div className="flex flex-col w-full max-w-6xl">
        <p className="font-noto-sans text-[16px] font-normal tracking-[0.03em] text-left">
          Certificate For
        </p>
        <div className="flex items-center justify-between flex-wrap">
          <p 
            className="font-noto-sans text-[23px] font-semibold  text-left mb-6"
            style={{ color: '#0778B6' }}
          >
            Selenium Basics to Advanced Workshop
          </p>   
        </div>
        <div className="flex flex-wrap w-full">
          {/* Left Box */}
          <div className="flex-1 bg-[#f0f9ff] p-2 rounded mb-4 mr-0 md:mr-4">
            <div className="flex items-center justify-between pt-2">
              <div className="flex">
              <img src={photoURL} alt="Profile" className="w-12 h-12 rounded-full" />
                <div className="flex flex-col ml-2">
                    <div className="flex items-center">
                    <h3 className="font-semibold">{profileName}</h3>
                    <img src={verificationIcon} alt="Verification" className="w-4 h-4 ml-1" title={`Verified & Authorized By: ${authorizedBy}`} />
                    </div>
                    <span className="text-sm text-gray-600">{email}</span>
                    {/*<div className="flex-1 pt-2">
                        <button className="bg-[#F3772F] text-white rounded shadow-md hover:shadow-lg transition " style={{ width: '95px', height: '35px' }}>
                            Portfolio
                        </button>
                        <button className="bg-[#0778B6] ml-2 text-white rounded shadow-md hover:shadow-lg transition" style={{ width: '95px', height: '35px' }}>
                            Hire Me
                        </button>
                    </div> */}
                </div>  
              </div> 
            </div>      
            <p className="mt-5 text-left justify-evenly font-sx pl-3 pr-3">{certificateDetails}</p>
            <h3 className="mt-4 pl-3 pr-3 text-lg font-semibold">Skills Covered:</h3>
            <div className="flex flex-wrap gap-2 pl-3 pr-3 mt-2 mb-2">
              {skills.map((skill, index) => (
                <span key={index} className="bg-[#e0e7ff] text-[#525252] rounded-full px-4 py-1 text-sm">
                  {skill}
                </span>
              ))}
            </div>
          </div>     
          {/* Right Box */}
          <div className="flex flex-col   bg-white" style={{ minWidth: '300px' }}>
            <img src={certificate} alt="Verified Certificate" className="w-full mb-2  border border-[#A8A8A8]" />
            <div className="flex flex-col  mb-2">
                <span className="text-sm text-black-600">Issued By: <a href='/trainings' target='_blank' style={{ color: '#0778B6' }}>{issuedby}</a></span>
                <span className="text-sm text-black-600">Authorized By: <a href='/' target='_blank' style={{ color: '#0778B6' }}>{authorizedBy}</a></span>
            </div>
          </div>      
        </div>
      </div>
    </div>
  );
};

export default CertificateMobile;