// CertificateWrapper.tsx
import React, { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CertificatePage from '../components/certificate/desktop/certificate';
import CertificateMobile from '../components/certificate/mobile/CertificateMobile';
import CreateNavbar from '../components/Navbar/Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { verifyCertificate } from '../api/Certificate';

const CertificateMainPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const navigate = useNavigate();
  const [data, setData] = useState<any>({});

  useLayoutEffect(() => {
    const initialize = async () => {
      const data = await verifyCertificate(id);
      setData(data);
      const isValid: any = data.verified
      if (isValid) {
        setLoading(false);
      } else {
        navigate("/notfound");
        setLoading(false);
      }
    };
    initialize();
  }, []);

  if (loading) {
    return <LoadingSpinner />; // Show spinner while loading
  }

  return (
    <div className="flex flex-col">
      <CreateNavbar page='home' />
      {isMobile ? (
        <CertificateMobile data={data} />
      ) : (
        <CertificatePage data={data} />
      )}
    </div>
  );
};

export default CertificateMainPage;
