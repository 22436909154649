import { isValidToken } from "../../api/UserMetadata";

export const isUserTokenValid = async () => {
      const token:any=localStorage.getItem('AccessToken')
      if(token===undefined || token===null){
        localStorage.clear();
        return false;
      }else if(token.length===0){
        localStorage.clear();
        return false;
      }
      const response:any=isValidToken(token);
      if(!response) localStorage.clear();
      return response;
}

