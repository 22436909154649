import axios from 'axios';
import { BackendApi } from './apiConfig/ApiFormatter';
import { isUserTokenValid } from '../components/common/ValidateToken';

// 1) Add Preparation Notes POST API
export const addPreparationNotes = async (
    body: {
        username: string;
        counsellor_email: string;
        notes: any[];
    },
    navigate: (path: string) => void // Additional parameter
): Promise<any> => {
    const isValid = await isUserTokenValid();
    if (!isValid) navigate('/login');

    const apiUrl = BackendApi() + "addpreprationNotes";
    try {
        const response = await axios.post(apiUrl, body, {
            headers: {
                'Authorization': `${localStorage.getItem('AccessToken')}`,
                'Content-Type': 'application/json'
            }
        });

        return response.data; // Return the data after successful addition
    } catch (error) {
        console.error('Error adding preparation notes:', error);
    }
};

// 2) Update Preparation Notes PUT API
export const preparationNotesUpdate = async (
    body: {
        username: string;
        counsellor_email: string;
        notes: any[];
    },
    navigate: (path: string) => void // Additional parameter
): Promise<any> => {
    const isValid = await isUserTokenValid();
    if (!isValid) navigate('/login');

    const apiUrl = BackendApi() + "preprationNotesUpdate";
    try {
        const response = await axios.put(apiUrl, body, {
            headers: {
                'Authorization': `${localStorage.getItem('AccessToken')}`,
                'Content-Type': 'application/json'
            }
        });


        return response.data; // Return the data after successful update
    } catch (error) {
        console.error('Error updating preparation notes:', error);
    }
};

// 3) View Preparation Notes POST API
export const viewPreparationNotes = async (
    body: {
        username: string;
        counsellor_email: string;
    },
    navigate: (path: string) => void // Additional parameter
): Promise<any> => {
    const isValid = await isUserTokenValid();
    if (!isValid) navigate('/login');

    const apiUrl = BackendApi() + "viewpreprationNotes";
    try {
        const response = await axios.post(apiUrl, body, {
            headers: {
                'Authorization': `${localStorage.getItem('AccessToken')}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data ; // Return the preparation notes data
    } catch (error) {
        console.error('Error viewing preparation notes:', error);
    }
};
