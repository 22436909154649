import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import mainLogo from '../../assets/img/logo.png';
import { InitiatePayment, verifyAndSavePaymentCredential } from '../../api/TrainingPayments';
import { useNavigate } from 'react-router-dom';
import { SnackbarComponent } from '../Snackbar/SnackbarComponent';

// const REDIRECT_PATH = '/premiumjourneyloading';
// Define types for responses and payloads
interface PaymentResponse {
  message: string;
  data: {
    razorpay_key: string;
    amount: number;
    currency: string;
    order_id: string;
    status: string;
    email: string;
    name: string;
    contact: string;
  };
}

interface VerifyAndSavePaymentCredentialResponse {
  status: number;
  data: {
    message: string;
  };
}

interface RazorpayButtonProps{
  buttonText: string;
  amount:string;
  name:string;
  email:string;
  phone:string;
  title:string;
  course_type:string;
}




export const RazorpayButton: React.FC<RazorpayButtonProps> = ({buttonText, amount, course_type,name, email, phone,title}) => {
  const [backendMessage, setBackendMessage] = useState<string>('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  
  const paymentResponseHandler = async (response: any) => {
    const now = new Date();
    const formattedTimestamp = now.toLocaleString();

    try {
      const re: VerifyAndSavePaymentCredentialResponse = await verifyAndSavePaymentCredential(response, formattedTimestamp,email);
      if (re.status === 200) {
        navigate('/trainings/success', { state: { fromRegistration: true } });
        
      } else if (re.status === 406 || re.status === 500) {
        setBackendMessage(re.data.message);
        setIsSnackbarOpen(true);
        setTimeout(() => {  
        }, 2000); // Give some time for the snackbar to show
      }
    } catch (error) {
      console.error('Error verifying and saving payment credential:', error);
      setIsSnackbarOpen(true);
      setTimeout(() => {
      }, 2000); // Give some time for the snackbar to show
    }
  };

  const paymentHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      
      const response: PaymentResponse = await InitiatePayment(amount, name, email, phone,title,course_type);
    
     
      if (response.data.status === 'created') {
        const options: any = {
          key: response.data.razorpay_key, // Your Razorpay key
          amount:response.data.amount*100, // Convert amount to paise if in INR
          currency: response.data.currency, // Currency code (e.g., "INR")
          name: 'North Star', // Your company or app name
          description: 'Training Payment', // Description of the transaction
          image: mainLogo, // URL or path to your company logo
          order_id: response.data.order_id, // Unique order ID from the server
          handler: paymentResponseHandler, // Function to handle payment response
          prefill: {
            name: response.data.name, // Customer name
            email: response.data.email, // Customer email
            contact: response.data.contact // Customer contact number
          },
          notes: {
            address: 'Skilljourney office' // Additional notes for the transaction
          },
          theme: {
            color: '#3399cc' // Custom color for the Razorpay widget
          },
          method: {
           
          }
        };

        const rzp1 = new (window as any).Razorpay(options);
        rzp1.open();
      } else if (response.data.status === 'paid') {
        setIsSnackbarOpen(true);
        setBackendMessage(response.data.status);
        setTimeout(() => {
        //   navigate(REDIRECT_PATH); // Navigate
        }, 1000); // Give some time for the snackbar to show
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      setIsSnackbarOpen(true);
      setTimeout(() => {  }, 2000); // Give some time for the snackbar to show
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    script.onerror = () => { throw new Error('Failed to Load Razorpay Script'); };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Button
        onClick={paymentHandler}
        variant="contained"
        color="primary"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#0778B6',
          fontWeight: '500',
          color: 'white',
          borderRadius: '8px',
          alignItems: 'center',
          fontSize: { xs: '0.875rem', sm: '0.8rem' },
          paddingY: 1,
          paddingX: { xs: 12, sm: 6 },
          '&:hover': {
            backgroundColor: '#1765c1',
          },
        }}
      >
        {buttonText}
      </Button>
      <SnackbarComponent isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} backendMessage={backendMessage} />
    </div>
  );
};
