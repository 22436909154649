import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Reusable BackButton component
export const BackButton = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // Go back to the previous page
    };

    return <div onClick={handleBack} className="cursor-pointer"> <ArrowBackIcon className="mb-1"  /> Portfolio</div> ;
};
