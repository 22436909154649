import React from 'react';

export const RadioBox: React.FC<any> = ({ field, draftData, setDraftData, isIframe }) => {
  return (
    <fieldset>
      <legend className="block text-base sm:text-lg text-description-text">
        {field.label} {field.required && <sup className="text-red-600 font-bold text-base">*</sup>}
      </legend>
      <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
        {field.options?.map((option: any) => (
          <div key={option.value} className="flex gap-1 py-1">
            <input
              type="radio"
              id={option.value}
              name={field.name}
              value={option.value}
              checked={draftData[field.id]?.startsWith(option.value)}
              onChange={!isIframe ? () => setDraftData((prev: any) => { return { ...prev, [field.id]: option.value } }) : undefined} // Disable change if isIframe
              disabled={isIframe} // Disable input if in iframe
            />
            <label htmlFor={option.value} className="cursor-pointer">{option.label}</label>
            {option.value === "other" && (
              <div className="w-full">
                <input
                  className={`border-b-2 border-zinc-200 w-full focus:outline-none focus:shadow-outline font-medium text-sm sm:text-base ${isIframe ? 'bg-gray-200 cursor-not-allowed' : ''}`} // Adjust styles if in iframe
                  type="text"
                  value={
                    typeof draftData[field.id] === "string" && draftData[field.id].startsWith("other-")
                      ? draftData[field.id].slice(6)
                      : ""
                  }
                  onChange={!isIframe ? (e) => {
                    const otherValue = "other-" + e.target.value;
                    setDraftData((prev: any) => ({
                      ...prev,
                      [field.id]: otherValue // Update with the new "other-" value
                    }));
                  } : undefined} // Disable change if isIframe
                  placeholder="Please specify"
                  disabled={isIframe} // Disable input if in iframe
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </fieldset>
  );
};
