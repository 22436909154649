import { useEffect } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Group26 from "../../assets/img/guidence/technology.png";
import Group30 from "../../assets/img/guidence/buisness.png";
import Group34 from "../../assets/img/guidence/arts.png";
import Group38 from "../../assets/img/guidence/health.png";
import Group42 from "../../assets/img/guidence/eng.png";
import Group43 from "../../assets/img/guidence/education.png";
import Group44 from "../../assets/img/guidence/law.png";
import Group45 from "../../assets/img/guidence/finance.png";
import Group46 from "../../assets/img/guidence/human.png";
import Group47 from "../../assets/img/guidence/sales.png";
import Group48 from "../../assets/img/guidence/data.png";
import Group49 from "../../assets/img/guidence/startup.png";
import Group50 from "../../assets/img/guidence/creative.png";
import Group51 from "../../assets/img/guidence/government.png";
import Group52 from "../../assets/img/guidence/agriculture.png";
import Group53 from "../../assets/img/guidence/communication.png";
import Group54 from "../../assets/img/guidence/sports.png";

function SliderOpportunities() {
    const navigate = useNavigate();

    useEffect(() => {
        const prevButton = document.querySelector(".swiper-button-prev") as HTMLElement | null;
        const nextButton = document.querySelector(".swiper-button-next") as HTMLElement | null;

        if (prevButton && nextButton) {
            const handlePrevClick = () => {
                prevButton.style.backgroundColor = "rgba(200, 200, 200, 0.9)";
                nextButton.style.backgroundColor = "white";
            };

            const handleNextClick = () => {
                nextButton.style.backgroundColor = "rgba(200, 200, 200, 0.9)";
                prevButton.style.backgroundColor = "white";
            };

            prevButton.addEventListener("click", handlePrevClick);
            nextButton.addEventListener("click", handleNextClick);

            return () => {
                prevButton.removeEventListener("click", handlePrevClick);
                nextButton.removeEventListener("click", handleNextClick);
            };
        }
    }, []);

    const handleImageClick = (index: number) => {
        navigate(`/exploreopportunities`);
        setTimeout(() => {
            window.location.hash = `${index}`;
        }); 
    };

    return (
        <div className="px-2 pb-20 md:w-max lg:w-4/5 md:mx-auto">
            <div className="mt-5">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={30}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                        },
                        640: {
                            slidesPerView: 4,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                        },
                        1280: {
                            slidesPerView: 5,
                        },
                    }}
                >
                    {[Group26, Group30, Group34, Group38, Group42, Group43, Group44, Group45, Group46, Group47, Group48, Group49, Group50, Group51, Group52, Group53, Group54].map((image, index) => (
                        <SwiperSlide key={index}>
                            <div className="relative w-fit" onClick={() => handleImageClick(index)}>
                                <img src={image} alt="" className="shadow-md cursor-pointer" />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="relative w-32 m-auto">
                    <div
                        className="swiper-button-prev"
                        style={{
                            position: "absolute",
                            top: "45px",
                            backgroundSize: "10px auto",
                            borderRadius: "50%",
                            backgroundColor: "white",
                            width: "40px",
                            height: "40px",
                            color: "#888",
                            border: "1px solid #d8d8d8",
                        }}
                    ></div>
                    <div
                        className="swiper-button-next"
                        style={{
                            position: "absolute",
                            top: "45px",
                            backgroundSize: "10px auto",
                            borderRadius: "50%",
                            backgroundColor: "white",
                            width: "40px",
                            height: "40px",
                            color: "#888",
                            border: "1px solid #d8d8d8",
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
}

export default SliderOpportunities;