import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { FetchJourney, FetchUserName } from "../api/FetchHtmlJourney";
import { isUserTokenValid } from "../components/common/ValidateToken";
import { JourneyStatusCheck } from "../api/JourneyStatusCheck";
import { storeUserData } from "../components/common/StorageUtil";
import BasicJourneyBody from "../components/journeys/BasicJourneyBody";
import LoadingSpinner from "../components/common/LoadingSpinner";
import useIsIframe from "../components/CounsellorUser/CounsellorsClientPortFolio/common/IsIframe";

export default function BasicJourneyAdmin() {
    const [shutter, setShutter] = useState(true);
    const [userName, setUserName] = useState<string>('');
    const [journeyPart, setJourneyPart] = useState<number>(0);
    const [standardJourney, setStandardJourney] = useState<any>({});
    const isIframe = useIsIframe();
    const navigate = useNavigate();
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const location = useLocation();
    const { redirect } = location.state || "";

    const fetchUserName = async () => {
        if (await isUserTokenValid()) {
            setUserName(await FetchUserName());
        } else {
            navigate('/');
        }
    };

    const fetchJourney = async () => {
        if (!(await isUserTokenValid())) {
            navigate('/');
            return;
        }

        const journeyCheck = await JourneyStatusCheck();

        if (journeyCheck?.journey_status !== 'initiated') {
            const journeyData = await FetchJourney();
            setStandardJourney(journeyData);
        }

        if (['completed', 'failed'].includes(journeyCheck?.journey_status)) {
            if (intervalRef.current) clearInterval(intervalRef.current);

            if (journeyCheck?.journey_status === 'completed') {
                setLoading(false);
            }
        } else {
            storeUserData('journeyExist', true);
        }
    };

    useEffect(() => {
        fetchUserName();
        fetchJourney();
        intervalRef.current = setInterval(fetchJourney, 5000);

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, []);
    useEffect(() => {
        if (redirect === "Questionnaire-to-MyJourney") {
            setLoading(false);
            window.history.replaceState({}, "");
        }
    }, [redirect]);

    if (loading) { return <LoadingSpinner />; }

    return (
        <div className="bg-white rounded-lg shadow-xl ml-2 border-2 border-gray-200">

            <BasicJourneyBody
                userName={userName}
                journeyPart={journeyPart}
                setShutter={setShutter}
                setJourneyPart={setJourneyPart}
                standardJourney={standardJourney.career_journey}
                hideNavbar={true}
                isIframe={isIframe}
            />
        </div>
    );
}
