import React, { useState, useEffect } from 'react';
import CreateNavbar from '../Navbar/Navbar'; // Adjust the import path as necessary
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for the editor
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'; // Importing arrow icons
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Session } from '../CounsellorUser/SessionsDetails/DetailedSessionPage';
import { useLocation } from 'react-router-dom';
import { createNotes, updateNotes, viewNotes } from '../../api/Counsellor';
import { BackButton } from './common/BackButton';


interface Note {
  id: string;
  category: string;
  content: string;
}

const StandardJourney: React.FC = () => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [noteCategory, setNoteCategory] = useState<string>(''); // State for note category
  const [isEditing, setIsEditing] = useState<boolean>(false); // New state for editing
  const [editNoteId, setEditNoteId] = useState<string | null>(null); // Track note being edited
  const [isEditorOpen, setIsEditorOpen] = useState<boolean>(true);
  const location = useLocation();
  const session = location.state?.currentSession as Session;

  const loadNotes = async () => {
    try {
      const fetchedNotes = await viewNotes(session.user_info.email, session.counselor.email, 'standard');

      const notesFromApi: Note[] = fetchedNotes.notes.map((note: any) => ({
        id: note.id,
        category: note.section,
        content: note.note,
      }));

      setNotes(notesFromApi);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  useEffect(() => {
    if (session) {
      loadNotes();
    }
  }, [session]);

  const addNote = async () => {
    if (newNote.trim() === '' || noteCategory === '') return;

    const newNoteObject: Note = {
      id: Date.now().toString(),
      category: noteCategory,
      content: newNote,
    };

    // Prepend the new note to display it at the top
    const updatedNotes = [newNoteObject, ...notes];
    setNotes(updatedNotes);
    clearNote(false);

    if (!session) {
      console.error('Session data is missing.');
    }

    const notesData = {
      username: session.clientName,
      journey_type: 'standard',
      version: 1,
      counsellor_email: session.counselor.email,
      notes: updatedNotes.map((note) => ({
        id: note.id,
        section: note.category,
        note: note.content,
      })),
    };

    try {
      if (notes.length === 0) {
        await createNotes(notesData);
      } else {
        await updateNotes(notesData);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error saving or updating note via API:', error.message);
      } else {
        console.error('An unexpected error occurred:', error);
      }
    }
  };


  const clearNote = (shouldOpenModal = false) => {
    setNewNote('');
    setNoteCategory('');
    setEditNoteId(null);
    setIsModalOpen(shouldOpenModal);
    setIsEditing(false);
  };


  const openEditModal = (note: Note) => {
    setIsModalOpen(true);
    setIsEditing(true);
    setEditNoteId(note.id);
    setNewNote(note.content);
    setNoteCategory(note.category);
  };

  const saveEditedNote = async () => {
    const updatedNotes = notes.map((note) =>
      note.id === editNoteId
        ? { ...note, content: newNote, category: noteCategory }
        : note
    );
    setNotes(updatedNotes);
    clearNote(false);
    if (!session) {
      console.error('Session data is missing.');
      return;
    }

    const notesData = {
      username: session.user_info.email,
      journey_type: 'standard',
      version: 1,
      counsellor_email: session.counselor.email,
      notes: updatedNotes.map((note) => ({
        id: note.id,
        section: note.category,
        note: note.content,
      })),
    };
    try {
      await updateNotes(notesData);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error updating note via API:', error.message);
      } else {
        console.error('An unexpected error occurred:', error);
      }
    }
  };

  const deleteNote = async (id: string) => {
    const updatedNotes = notes.filter((note) => note.id !== id);
    setNotes(updatedNotes);

    if (!session) {
      console.error('Session data is missing.');
      return;
    }

    const notesData = {
      username: session.user_info.email,
      journey_type: 'standard',
      version: 1,
      counsellor_email: session.counselor.email,
      notes: updatedNotes.map((note) => ({
        id: note.id,
        section: note.category,
        note: note.content,
      })),
    };

    try {
      await updateNotes(notesData);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error deleting note via API:', error.message);
      } else {
        console.error('An unexpected error occurred:', error);
      }
    }
  };

  return (
    <>
      <CreateNavbar page="notepad" />
      <div className="bg-gray-100 min-h-screen flex flex-col md:flex-row-reverse">
        <div className={`pt-1 w-full ${isEditorOpen ? 'md:w-5/6' : 'md:w-full'} h-[80vh] md:h-screen md:mr-2 transition-all`}>
          <iframe
            src={`/StandardJourneyAdmin?user_info=${JSON.stringify(session.user_info)}`}
            title="Standard Journey"
            className="w-full pb-10 h-full border-0"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          />
        </div>

        {/* Editor Section */}
        <div
          className={`relative transition-all ${isEditorOpen ? 'w-full md:w-1/6' : 'w-0 md:w-0 overflow-hidden'
            } h-auto bg-white p-4 rounded-lg shadow-md border border-gray-300`}
        >
          <div className='flex justify-between items-center mb-4'>
            {isEditorOpen && <BackButton />}
            <button
              onClick={() => setIsEditorOpen(!isEditorOpen)}
              className="text-blue-500 hover:text-blue-700"
            >
              {isEditorOpen ? <FaChevronLeft /> : <FaChevronRight />}
            </button>
          </div>
          {isEditorOpen && (
            <>
              <div className="flex flex-col mb-4 space-y-2 md:flex-row md:space-y-0 md:space-x-2">
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="px-4 py-2 bg-[#0778b6] text-white font-semibold rounded-lg shadow-md hover:bg-[#055c91] transition-colors duration-300 w-full"
                >
                  Add Note
                </button>
              </div>
              <h2 className="text-lg font-bold mt-4 mb-2">Saved Notes</h2>
              <div className="grid grid-cols-1 gap-3 max-h-[75vh] overflow-y-auto">
                {notes.map((note) => (
                  <div
                    key={note.id}
                    className="bg-gray-50 p-1 rounded-md border border-gray-200"
                  >
                    <p className="text-sm font-semibold overflow-hidden text-ellipsis whitespace-nowrap">
                      {note.category}
                    </p>
                    <div
                      className="text-gray-800 mb-2 text-sm break-words overflow-hidden"
                      dangerouslySetInnerHTML={{ __html: note.content }}
                    />
                    <div className="flex justify-end ">
                      <IconButton onClick={() => openEditModal(note)} aria-label="edit">
                        <EditIcon fontSize="small" style={{ color: '#0778b6' }} />
                      </IconButton>
                      <IconButton onClick={() => deleteNote(note.id)} aria-label="delete">
                        <DeleteIcon fontSize="small" style={{ color: '#e5383b' }} />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {/* Modal for Adding/Editing Notes */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-4 w-11/12 md:w-1/3 shadow-md">
            <h2 className="text-lg font-bold mb-2">
              {isEditing ? 'Edit Note' : 'Add Note'}
            </h2>
            {/* Dropdown for selecting the note category */}
            <div className="mb-4">
              <label
                htmlFor="noteCategory"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Select Section:
              </label>
              <select
                id="noteCategory"
                value={noteCategory}
                onChange={(e) => setNoteCategory(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">-- Select Section --</option>
                <option value="Year 1">Year 1</option>
                <option value="Year 2">Year 2</option>
                <option value="Year 3">Year 3</option>
                <option value="Year 4">Year 4</option>
                <option value="Year 5">Year 5</option>
                <option value="Alternate Career Journeys">
                  Alternate Career Journeys
                </option>
              </select>
            </div>
            {/* ReactQuill editor for writing/editing the note */}
            <ReactQuill
              value={newNote}
              onChange={setNewNote}
              className="h-64"
              placeholder="Write your note here..."
            />
            {/* Buttons for clear, save, and close */}
            <div className="flex pt-8 justify-between mt-4">
              <button
                onClick={() => clearNote(true)}
                className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 transition-colors duration-300"
              >
                Clear
              </button>
              <button
                onClick={isEditing ? saveEditedNote : addNote}
                className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300"
              >
                {isEditing ? 'Save Changes' : 'Save'}
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 font-semibold rounded-lg shadow-md hover:bg-gray-400 transition-colors duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StandardJourney;
