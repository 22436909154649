import React, { useCallback, useEffect, useState } from 'react';
import PremiumJourneyBody from '../components/PremiumJourneys/PremiumJourneyBody';
import '../components/PremiumJourneys/PremiumJourneyBody.css'
import { FetchPremiumJourney } from '../api/FetchHtmlJourney';
import { isUserTokenValid } from '../components/common/ValidateToken';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { isMobile } from 'react-device-detect';
import LeftPremiumSidebar from '../components/sidebar/LeftPremiumSidebar';
import RightPremiumSidebar from '../components/sidebar/RightPremiumSidebar';
import useIsIframe from '../components/CounsellorUser/CounsellorsClientPortFolio/common/IsIframe';

const PremiumJourneyAdmin: React.FC = () => {
  const [premiumJourney, setPremiumJourney] = useState<any>({});
  const [isResponsive, setIsResponsive] = useState<boolean>(false);
  const navigate = useNavigate();
  const isIframe = useIsIframe();
  
  const handleResize = useCallback(() => {
    setIsResponsive(window.innerWidth < 768);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);


  const fetchPremiumJourney = async () => {
    if (!(await isUserTokenValid())) {
        navigate('/login',{ state:{page:"pcj"} });
        return;
    }
        const journeyData = await FetchPremiumJourney();
        setPremiumJourney(journeyData);
    }

  useEffect(() => { fetchPremiumJourney(); }, []);

  if (!['Premium', 'Counselor'].includes(localStorage.getItem("userLevel") || '')) navigate('*', { state: { redirect: 'Premium-journey-page-unauthorised' } });

  return (
    <div className="h-screen font-sans">
      {premiumJourney.career_premium_journey ? 
        <div className="flex flex-row w-full h-full  md:pb-5  justify-evenly">
          <LeftPremiumSidebar visible={!isResponsive} />
          <PremiumJourneyBody premiumJourney = {premiumJourney.career_premium_journey} isIframe ={!isIframe}/> 
        {!isMobile && <RightPremiumSidebar premiumJourney = {premiumJourney.career_premium_journey}  />}
      </div>
      : <LoadingSpinner /> }
  </div>
  );
};

export default PremiumJourneyAdmin;
