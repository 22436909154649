import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import reaching from "../assets/img/support.svg";
import CreateNavbar from '../components/Navbar/Navbar';

interface FormValues {
    name: string;
    email: string;
    tel: string;
    message: string;
    category: string;  // Added category to FormValues
}

const Technical: React.FC = () => {

    const [formValues, setFormValues] = useState<FormValues>({
        name: '',
        email: '',
        tel: '',
        message: '',
        category: '',  // Initial value is empty
    });

    const [error, setError] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [telError, setTelError] = useState('');
    const [messageError, setMessageError] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        // Clear error when clicking on another input
        if (name === 'email' && nameError === 'You can enter a maximum of 50 characters in the name field.') {
            setNameError('');
        }

        if (name === 'tel' && nameError === 'You can enter a maximum of 50 characters in the name field.') {
            setNameError('');
        }
        if (name === 'message' && nameError === 'You can enter a maximum of 50 characters in the name field.') {
            setNameError('');
        }
        if (name === 'message' && telError === 'Contact number must not exceed 20 digits including spaces.') {
            setTelError('');
        }
        if (name === 'name' && telError === 'Contact number must not exceed 20 digits including spaces.') {
            setTelError('');
        }
        if (name === 'email' && telError === 'Contact number must not exceed 20 digits including spaces.') {
            setTelError('');
        }
        if (name === 'name') {
            const nameRegex = /^[a-zA-Z\s]*$/;
            if (!nameRegex.test(value)) {
                setNameError('Name should contain only characters and spaces.');
                return;
            } else if (value.length > 49) {
                setNameError('You can enter a maximum of 50 characters in the name field.');
                return;
            } else {
                setNameError('');
            }
        }


        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setEmailError('Please enter a valid email address.');
            } else {
                setEmailError('');
            }
        }


        if (name === 'tel') {
            const telRegex = /^[0-9+\- )(]*$/;
            if (!telRegex.test(value)) {
                setTelError('Contact number must contain only numbers, +, -, (), and spaces.');
                return;
            } else if (value.length > 20) { // Include spaces for length check
                setTelError('Contact number must not exceed 20 digits including spaces.');
                return;
            } else {
                setTelError('');
            }
        }

        if (name === 'message') {
            const messageLength = value.length; // Include spaces in length
            if (messageLength > 249) {
                setMessageError('Message must be between 20 and 250 characters.');
                return; // Prevent updating the state if the message length exceeds 250
            } else if (messageLength < 20) {
                setMessageError('Message must be between 20 and 250 characters.');
            } else {
                setMessageError(''); // Clear error message if input is valid
            }

        }

        setFormValues({
            ...formValues,
            [name]: value
        });
    };
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { name, email, tel, message, category } = formValues;

        if (!name || !email || !tel || !message) {
            setError('Please fill all the required fields.');
            return;
        }

        // Prepare form data
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('tel', tel);
        formData.append('category', category);
        formData.append('message', message);

        // Send the email
        emailjs.send(
            'service_939ebkx',
            'template_ckoygzn',
            {
                to_name: "support@skilljourney.in",
                from_name: name,
                from_email: email,
                from_contact: tel,
                category: category,
                message: message,
            },
            'Fy_J1wFB4LmPKrSlo'
        ).then((response) => {
            alert('Email sent successfully!' + response.text);
            setError('');
            setFormValues({ name: '', email: '', tel: '', message: '', category: '' });
        }).catch((error) => {
            console.error('Failed to send email:', error);
            setError('Failed to send email. Please try again later.');
        });
    };

    return (
        <div className="h-screen bg-white">
            <CreateNavbar page='contact' />
            <div className="absolute left-1/2 transform -translate-x-1/2 text-center">
                <h1 className="font-sans font-extrabold text-2xl leading-tight tracking-tight text-black">
                    Help Center
                </h1>
                <h1 className="font-sans font-normal md:mt-2 mt-7 text-sm leading-tight tracking-tight text-black md:text-base md:w-[700px] mx-auto w-[370px] md:block">
                    Please send us your support query below. We will do our best to resolve it soon.
                </h1>
            </div>
            <div className="absolute md:top-[140px] top-[170px] left-1/2 transform -translate-x-1/2 w-full max-w-[900px] h-auto md:h-[570px] bg-white rounded-[27px] md:shadow-home p-4 md:p-0">
                <div className="flex flex-col md:flex-row items-center">
                    <div className="relative w-96 md:w-[600px] h-96 md:h-[550px] top-5 md:pb-0 md:pr-7 pb-20 pr-7 md:mb-0 mb-6">
                        <img src={reaching} alt="Message Icon" className="py-14 md:pl-14 pl-8" />
                    </div>

                    <div className="w-full  md:w-[500px] h-auto md:h-[150px] p-8 md:-mt-80  md:ml-2">
                        <form className="md:-m-5" onSubmit={handleSubmit}>
                            <div className="md:mb-6  mb-9 relative">
                                <label htmlFor="name" className="block font-sans font-medium text-[14px] leading-[21px] tracking-tight text-gray-400">
                                    Name <span className="text-red-600">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formValues.name}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded pt-5 w-full h-[40px] font-inter font-medium text-[16px] leading-[24px] tracking-tight text-black focus:outline-none focus:border-blue-500 focus:shadow-none"
                                />
                                {nameError && (
                                    <div className="
    absolute  left-0 text-red-600 font-sans font-medium text-[14px] 
    leading-4 tracking-wide">
                                        {nameError}
                                    </div>
                                )}

                            </div>

                            <div className="md:mb-4 mb-9 relative">
                                <label htmlFor="email" className="block font-sans font-medium text-[14px] leading-[21px] tracking-tight text-gray-400">
                                    Email <span className="text-red-600">*</span>
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded pt-5 w-full h-[40px] font-inter font-medium text-[16px] leading-[24px] tracking-tight text-black focus:outline-none focus:border-blue-500 focus:shadow-none"
                                />
                                {emailError && (
                                    <div className="
    absolute  left-0 text-red-600 font-sans font-medium text-[14px] leading-4 tracking-tight">
                                        {emailError}
                                    </div>
                                )}
                            </div>
                            <div className="md:mb-4 mb-9 relative">
                                <label htmlFor="tel" className="block font-sans font-medium text-[14px] leading-[21px] tracking-tight text-gray-400">
                                    Contact Number <span className="text-red-600">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="tel"
                                    name="tel"
                                    value={formValues.tel}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded pt-5 w-full h-[40px] font-inter font-medium text-[16px] leading-[24px] tracking-tight text-black focus:outline-none focus:border-blue-500 focus:shadow-none"
                                />
                                {telError && (
                                    <div className="
    absolute  left-0 text-red-600 font-sans font-medium text-[14px] leading-3 tracking-tight">
                                        {telError}
                                    </div>
                                )}

                            </div>
                            <div className="md:mb-4 mb-9 relative">
                                <label htmlFor="category" className="block font-sans font-medium text-[14px] leading-[21px] tracking-tight text-gray-400 ">
                                    Category <span className="text-red-600">*</span>
                                </label>
                                <select
                                    id="category"
                                    name="category"
                                    // value={formValues.category}
                                    // onChange={handleChange}
                                    className="border border-gray-300 rounded pt-5 w-full h-[40px] font-inter font-medium text-[16px] leading-[24px] tracking-tight text-black focus:outline-none focus:border-blue-500 focus:shadow-none"
                                >
                                    <option value="">Select a category</option>
                                    <option value="Journeys">Journeys</option>
                                    <option value="Counselling">Counselling</option>
                                    <option value="Training">Training</option>

                                </select>
                            </div>
                            <div className="md:mb-4 mb-9 relative">
                                <label htmlFor="message" className="block font-sans font-medium text-[14px] leading-[21px] tracking-tight text-gray-400">
                                    Message <span className="text-red-600">*</span>
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formValues.message}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded pt-2 w-full h-[90px] font-inter font-medium text-[16px] leading-[24px] tracking-tight text-black focus:outline-none focus:border-blue-500 focus:shadow-none resize-none"
                                />
                                {messageError && (
                                    <div className="absolute left-0 text-red-600 font-sans font-medium text-[14px] leading-4 tracking-tight">
                                        {messageError}
                                    </div>
                                )}
                            </div>


                            <div className="flex justify-end md:mt-0 mt-5">
                                {error && (
                                    <div className="text-red-600 items-center justify-center font-sans font-medium text-[14px] leading-4 tracking-tight md:mr-16 mr-2 py-3">
                                        {error}
                                    </div>
                                )}
                                <button
                                    type="submit"
                                    className="bg-[#0778B6] text-white font-sans font-medium text-base rounded-[8px] md:w-[110px] w-[90px] h-[35px] focus:outline-none flex items-center justify-center"
                                >
                                    <span className="mr-2">Send</span>

                                </button>


                            </div>
                        </form>

                    </div>

                </div>
                <div className="flex flex-wrap justify-center mt-[60px] ple-3 font-sans text-sm text-gray-500">

                    <Link to="/privacypolicy" className="px-2">Privacy Policy</Link>
                    <span className="-px-1">|</span>
                    <Link to="/terms&conditions" className="px-2">Terms & Conditions</Link>
                    <span className="-px-1">|</span>
                    <Link to="/cancellation&refund" className="px-2">Cancellation & Refund</Link>
                    <span className="-px-1">|</span>
                    <Link to="/shipping" className="px-1">Shipping & Delivery</Link>
                </div>
                <div className="py-3"></div>
            </div>
        </div>
    );
};

export default Technical;
