import { getQueryParam } from "../components/CounsellorUser/CounsellorsClientPortFolio/common/util";
import { BackendApi } from "./apiConfig/ApiFormatter";

const fetchJourneyData = async (endpoint: string) => {
  const apiUrl = BackendApi() + endpoint;
  const user_info = JSON.parse(getQueryParam('user_info') as string) || null;
  // eslint-disable-next-line react-hooks/rules-of-hooks

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("AccessToken")}`,
      },
      body: JSON.stringify({ "email": user_info ? user_info.email : localStorage.getItem("Email") })
    });

    if (!response.ok) {
      console.log('Network response was not ok');
    }

    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const FetchJourney = async () => {
  return await fetchJourneyData("fetchStandardJourney");
};

export const FetchPremiumJourney = async () => {
  return await fetchJourneyData("getPremiumJourney");
};

export const FetchUserName = async (): Promise<any> => {
  const apiUrl = BackendApi() + "readUserQuestionnaire";

  try {
    const idToken = localStorage.getItem("AccessToken"); // Fetch the ID token from session storage

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `${idToken}`, // Include the id_token in the Authorization header
      },
      body: JSON.stringify({ "email": localStorage.getItem("Email") })
    });

    if (!response.ok) {
      console.log("Error fetching user questionnaire");
    }

    const questionWithAnswers = await response.json();
    const questionWithAnswersArray = questionWithAnswers.data[0];
    const FullNameArray = questionWithAnswersArray.questions[0];
    return FullNameArray.reply;
  } catch (error) {
    console.error("Error fetching user questionnaire:", error);
    throw error; // Rethrow error to handle it in the component
  }
};
