import axios from 'axios';
import { AdminApi } from './apiConfig/ApiFormatter';

export const sendCounsellingFeedback = async (
    email:any,
    counsellor_email:string,
    username:any
): Promise<any> => {
    const apiUrl = `${AdminApi()}sendEmail`;

    // Updated HTML content for the email body
    const emailBody = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Premium Career Journey Ready</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                background-color: #f9f9f9;
                margin: 0;
                padding: 20px;
            }
            .container {
                background-color: #ffffff;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                padding: 20px;
                max-width: 600px;
                margin: auto;
            }
            .logo {
                text-align: center;
                margin-bottom: 20px;
            }
            .logo img {
                max-width: 150px; /* Adjust as necessary */
            }
            h1 {
                color: #333;
            }
            p {
                color: #555;
                line-height: 1.6;
            }
            .link {
                display: inline-block;
                margin-top: 15px;
                padding: 10px 15px;
                background-color: #007bff;
                color: white;
                text-decoration: none;
                border-radius: 5px;
            }
            .link:hover {
                background-color: #0056b3;
            }
            .footer {
                margin-top: 20px;
                font-size: 0.9em;
                color: #777;
            }
        </style>
    </head>
    <body>
        <div class="container">
            
            <p>Dear ${username},</p>

            <p>Your counsellor feedback is now available for you to review.You can check it by going to the counselling section on SkillJourney. If you have any questions about the feedback you can write to us at counselling@skilljourney.in </p>

            <p>For further assistance, you can also schedule another appointment with any of our panel counsellors.
Thank you for choosing Skill Journey for your counselling needs, and we wish you all the best in your personal and professional growth.</p>

            <p>Best Regards,<br>The SkillJourney Team</p>
        </div>
    </body>
    </html>
    `;

    const requestBody = {
        user_email: email,
        sender_email: "counselling@skilljourney.in",
        subject: "Your Counselling Feedback is now Available!",
        body: emailBody, // HTML content for the body
        body_type: "HTML", // Specify that the body is HTML
        bcc_recipients: ["admin@skilljourney.in",counsellor_email],
    };

    try {
        const response = await axios.post(apiUrl, requestBody, {
            headers: {
                'Authorization': `${localStorage.getItem('AccessToken')}`,
                'Content-Type': 'application/json'
            }
        });

        return response.data; // Return the response data
    } catch (error) {
        console.error('Error sending email:', error);
    }
};
