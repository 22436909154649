import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import mainLogo from '../../assets/img/logo.png';
import { SnackbarComponent } from '../Snackbar/SnackbarComponent';
import { InitiatePayment, verifyAndSavePaymentCredential } from '../../api/CounseelingPayemnts';
import { createMeeting } from '../../api/Meetings';
import { useNavigate } from 'react-router-dom';

interface PaymentResponse {
  message: string;
  data: {
    razorpay_key: string;
    amount: number;
    currency: string;
    order_id: string;
    status: string;
    email: string;
    name: string;
    contact: string;
  };
}

interface VerifyAndSavePaymentCredentialResponse {
  status: number;
  data: {
    message: string;
  };
}

interface RazorpayButtonProps {
  buttonText: string;
  amount: string;
  name: string;
  useremail: string;
  phone: string;
  title: string;
  course_type: string;
  counsellor_email: string;
  date: string;
  slot: string;
}

export const RazorpayButton: React.FC<RazorpayButtonProps> = ({
  buttonText,
  amount,
  course_type,
  name,
  useremail,
  phone,
  counsellor_email,
  title,
  date,
  slot,
}) => {
  const [backendMessage, setBackendMessage] = useState<string>('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const orderIDRef = useRef<string | null>(null);
  const navigate = useNavigate();


  const paymentResponseHandler = async (response: any) => {
    const now = new Date();
    const formattedTimestamp = now.toLocaleString();

    if (!orderIDRef.current) {
      console.error('Order ID is not available');
      return;
    }

    try {
      const content = `Thank you for booking a counseling session with us.<br> We have successfully scheduled your session with <strong>${counsellor_email}</strong>.<br>
    
    Below are the details of your session:<br>
    - *Session Title*: ${title}<br>
    - *Date*: ${date}<br>
    - *Slot*: ${slot}<br>
    
    We look forward to assisting you during your session. If you have any questions or need to reschedule, please do not hesitate to contact us.<br>
    
    Best regards,  <br>
    The SkillJourney Team`;
    
      const re: VerifyAndSavePaymentCredentialResponse = await verifyAndSavePaymentCredential(response, formattedTimestamp, useremail);
    
      if (re.status === 200) {
        const res = await createMeeting(title, date, slot, [counsellor_email], [useremail], orderIDRef.current, content);
            
        if (res && res.meeting_id) {
          navigate('/booked-sessions')
        } else {
          alert("Meeting not scheduled, try again.")
        }
      } else if (re.status === 406 || re.status === 500) {
        setBackendMessage(re.data.message);
        setIsSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error verifying and saving payment credential:', error);
      setBackendMessage('Error verifying payment.');
      setIsSnackbarOpen(true);
    }    
  };

  const paymentHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const response: PaymentResponse = await InitiatePayment(amount, name, useremail, phone, title, course_type);

      if (response.data.status === 'created') {
        orderIDRef.current = response.data.order_id;
        const options: any = {
          key: response.data.razorpay_key,
          amount: response.data.amount * 100,
          currency: response.data.currency,
          name: 'SkillJourney Trainings',
          description: 'Training Payment',
          image: mainLogo,
          order_id: response.data.order_id,
          handler: paymentResponseHandler,
          prefill: {
            name: response.data.name,
            email: response.data.email,
            contact: response.data.contact,
          },
          notes: {
            address: 'Skilljourney office',
          },
          theme: {
            color: '#3399cc',
          },
        };
        const rzp1 = new (window as any).Razorpay(options);
        rzp1.open();
      } else if (response.data.status === 'paid') {
        setIsSnackbarOpen(true);
        setBackendMessage('Payment has already been processed.');
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      setBackendMessage('Error initiating payment.');
      setIsSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = () => console.log('Razorpay script loaded successfully');
    script.onerror = () => {
      console.error('Failed to load Razorpay script');
      throw new Error('Failed to load Razorpay script');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Button
        onClick={paymentHandler}
        variant="contained"
        color="primary"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#0778B6',
          fontWeight: '500',
          color: 'white',
          borderRadius: '8px',
          alignItems: 'center',
          fontSize: { xs: '0.875rem', sm: '0.8rem' },
          paddingY: 1,
          paddingX: { xs: 12, sm: 6 },
          '&:hover': {
            backgroundColor: '#1765c1',
          },
        }}
      >
        {buttonText}
      </Button>
      <SnackbarComponent isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} backendMessage={backendMessage} />
    </div>
  );
};
