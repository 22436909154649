import * as React from 'react'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Paper, { PaperProps } from '@mui/material/Paper'
import Draggable from 'react-draggable'
import { Session } from '../../SessionsDetails/DetailedSessionPage'
import ReactQuill from 'react-quill'
import { updateFeedback } from '../../../../api/updateFeedback'
import { useNavigate } from 'react-router-dom'
import { sendCounsellingFeedback } from '../../../../api/SendEmailApi'

export function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface FeedbackPopupProps {
  currentSession: Session | null
  isFeedbackOpen: boolean
  setIsFeedbackOpen: (open: boolean) => void
  onFeedbackUpdate: (feedback: string) => void
  onStatusUpdate: (status: string) => void // Added prop for updating status
  currentFeedback: string
  currentStatus: string
}

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({
  currentSession,
  isFeedbackOpen,
  setIsFeedbackOpen,
  onFeedbackUpdate,
  onStatusUpdate,
  currentFeedback,
  currentStatus,
}) => {
  const navigate = useNavigate()
  const [feedback, setFeedback] = useState('')
  const [status, setStatus] = useState('')

  const handleSaveFeedback = async () => {
    const newStatus =
      currentStatus === 'Completed'
        ? 'Updated_draft'
        : currentStatus === 'Updated_draft'
          ? 'Updated_draft'
          : currentStatus === 'Awaiting_feedback'
            ? 'Updated'
            : 'Updated';
  
    const response = await updateFeedback(
      navigate,
      currentSession?.clientName,
      currentSession?.counselor.email,
      currentSession?.date,
      currentSession?.time,
      undefined,
      feedback,
      currentSession?.timestamp,
      newStatus
    );
  
    if (response?.status === 200) {
      onFeedbackUpdate(feedback); // Pass the current feedback
      onStatusUpdate(newStatus);
      setIsFeedbackOpen(false);
      alert("Feedback updated successfully.");
    }
  };
  
  const handleSendFeedback = async () => {
    const response = await updateFeedback(
      navigate,
      currentSession?.clientName,
      currentSession?.counselor.email,
      currentSession?.date,
      currentSession?.time,
      feedback,
      undefined,
      currentSession?.timestamp,
      'Completed'
    );
  
    if (response.status === 200) {
      const counsellor: any = currentSession?.counselor.email;
      const userName: any = currentSession?.user_info.name || currentSession?.clientName;
  
      sendCounsellingFeedback(currentSession?.clientName, counsellor, userName);
      onFeedbackUpdate(feedback);
      onStatusUpdate('Completed');
      setIsFeedbackOpen(false);
      alert("Feedback sent successfully."); 
      setFeedback('');
      navigate('/counsellor/dashboard');
    }
  };
  

  useEffect(() => {
    if (isFeedbackOpen) {
      setFeedback(currentFeedback) // Initialize feedback when dialog opens
      setStatus(currentStatus) // Initialize status when dialog opens
    }
  }, [isFeedbackOpen, currentFeedback, currentStatus])

  const handleClose = () => {
    setIsFeedbackOpen(false)
  }

  return (
    <React.Fragment>
      <Dialog
        open={isFeedbackOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        sx={{ '& .MuiDialog-paper': { width: '600px', height: '600px' } }} // Custom width and height
        maxWidth="md"
        fullWidth
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Submit Your Feedback
          <button
            onClick={handleClose}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            &times; {/* Close icon (×) */}
          </button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="flex items-center mb-2">
              <img
                src={currentSession?.counselor.profileUrl}
                alt={currentSession?.clientName}
                className="size-14 md:size-24 rounded-full border-4 border-blue-500 mr-4"
              />
              <div>
                <h3 className="text-xl md:text-2xl font-semibold text-gray-800 mb-1 w-52 md:w-80 truncate">
                  {currentSession?.clientName}
                </h3>
                <p className="text-gray-600 text-lg">
                  <strong>Date:</strong> {currentSession?.date}
                </p>
                <p className="text-gray-600 text-lg">
                  <strong>Time:</strong> {currentSession?.time}
                </p>
                <p
                  className={`text-lg ${status === 'Completed'
                      ? 'text-green-600'
                      : status === 'Cancelled'
                        ? 'text-red-600'
                        : status === 'Awaiting_feedback'
                          ? 'text-orange-600'
                          : status === 'Expired'
                            ? 'text-gray-500'
                            : 'text-yellow-600'
                    }`}
                >
                  <strong>Status:</strong>{' '}
                  {status === 'Awaiting_feedback'
                    ? 'Awaiting Feedback'
                    : status === 'Expired'
                      ? 'Expired'
                      : status}
                  {/* Display the updated status */}
                </p>
              </div>
            </div>
            <ReactQuill
              value={feedback}
              onChange={setFeedback}
              className="h-64"
              placeholder="Enter your feedback here..."
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendFeedback}>Send</Button>
          <Button
            onClick={handleSaveFeedback}
            className="bg-gray-500 text-white"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default FeedbackPopup;
