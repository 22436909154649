import { useEffect, useState } from 'react';

const useIsIframe = (): boolean => {
  const [isIframe, setIsIframe] = useState<boolean>(false);

  useEffect(() => {
    setIsIframe(window.self !== window.top); // Detect if inside an iframe
  }, []);

  return isIframe;
};

// Exporting the custom hook directly
export default useIsIframe;
