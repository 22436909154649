import React, { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import message from "../assets/img/message.png";
import inbox from "../assets/img/inbox.png";
import map from "../assets/img/map.png";
import device from "../assets/img/device.png";
import send from "../assets/img/send.png";
import CreateNavbar from '../components/Navbar/Navbar';

const Contact: React.FC = () => {

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
}, []);

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    tel: '',
    message: ''
  });

  const [error, setError] = useState('');
  const [nameError, setnameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [telError, setTelError] = useState('');
  const [messageError, setMessageError] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    // Clear error when clicking on another input
    if (name === 'email' && nameError === 'You can enter a maximum of 50 characters in the name field.') {
      setnameError('');
  }
  
    if (name === 'tel' && nameError === 'You can enter a maximum of 50 characters in the name field.') {
      setnameError('');
    }
    if (name === 'message' && nameError === 'You can enter a maximum of 50 characters in the name field.') {
      setnameError('');
    }
    if (name === 'message' && telError === 'Contact number must not exceed 20 digits including spaces.') {
      setTelError('');
    }
    if (name === 'name' && telError === 'Contact number must not exceed 20 digits including spaces.') {
      setTelError('');
    }
    if (name === 'email' && telError === 'Contact number must not exceed 20 digits including spaces.') {
      setTelError('');
    }
    if (name === 'name') {
      const nameRegex = /^[a-zA-Z\s]*$/;
      if (!nameRegex.test(value)) {
        setnameError('Name should contain only characters and spaces.');
        return;
      } else if (value.length > 49) {
        setnameError('You can enter a maximum of 50 characters in the name field.');
        return;
      } else {
        setnameError('');
      }
    }


    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError('Please enter a valid email address.');
      } else {
        setEmailError('');
      }
    }


    if (name === 'tel') {
      const telRegex = /^[0-9+\- )(]*$/;
      if (!telRegex.test(value)) {
        setTelError('Contact number must contain only numbers, +, -, (), and spaces.');
        return;
      } else if (value.length > 20) { // Include spaces for length check
        setTelError('Contact number must not exceed 20 digits including spaces.');
        return;
      } else {
        setTelError('');
      }
    }

    if (name === 'message') {
      const messageLength = value.length; // Include spaces in length
      if (messageLength > 249) {
        setMessageError('Message must be between 20 and 250 characters.');
        return; // Prevent updating the state if the message length exceeds 250
      } else if (messageLength < 20) {
        setMessageError('Message must be between 20 and 250 characters.');
      } else {
        setMessageError(''); // Clear error message if input is valid
      }

    }

    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Clear previous name error if present
    setnameError('');
    setEmailError('');
    setTelError('');
    setMessageError('');
    const { name, email, tel, message } = formValues;

    // Check if any required field is empty
    if (!name || !email || !tel || !message) {
      setError('Please fill all the required fields.');
      return;
    }

    const messageLength = formValues.message.trim().length;
    if (messageLength < 20 || messageLength > 250) {
      setError('Message must be between 20 and 250 characters.');
      return;
    }

    emailjs.send(
      'service_939ebkx', // Replace with your EmailJS service ID
      'template_ckoygzn', // Replace with your EmailJS template ID
      {
        to_name: "support@skilljourney.in",
        from_name: formValues.name,
        from_email: formValues.email,
        from_contact: formValues.tel,
        message: formValues.message
      },
      'Fy_J1wFB4LmPKrSlo' // Replace with your EmailJS user ID
    ).then((response) => {
      alert('Email sent successfully!' + response.text);
      setError(''); // Clear any previous errors
      setFormValues({ name: '', email: '', tel: '', message: '' });
    }).catch((error) => {
      console.error('Failed to send email:', error);
      setError('Failed to send email. Please try again later.');
    });
  };

  return (
    <div className="h-screen bg-white">
      <CreateNavbar page='contact' />
      <div className="absolute left-1/2 transform -translate-x-1/2 text-center">
        <h1 className="font-sans font-extrabold text-2xl leading-tight tracking-tight text-black">
          Get In Touch
        </h1>
        <h1 className="font-sans font-normal md:mt-2 mt-7 text-sm leading-tight tracking-tight text-black md:text-base md:w-[700px] mx-auto w-[370px] md:block">
          Have questions, issues, or feedback? Contact us to help you navigate your career journey with SkillJourney!
        </h1>
      </div>
      <div className="absolute top-[170px] left-1/2 transform -translate-x-1/2 w-full max-w-[850px] h-auto md:h-[455px] bg-white rounded-[27px] md:shadow-home p-4 md:p-0">
        <div className="flex flex-col md:flex-row items-center">
          <div className="relative max-w-screen-2xl  md:w-[387px] h-96 md:h-[440px] md:left-2 top-2  bg-custom-blue rounded-[20px] pb-20 pr-7 md:mb-0 mb-6">
            <h1 className="font-sans font-medium md:mt-6 mt-6 text-2xl leading-tight tracking-tight text-white text-center">
              Contact Information
            </h1>
            <div className="flex items-center md:mt-10 mt-10">
              <img src={message} alt="Message Icon" className="h-5 w-5 ml-10" />
              <span className="text-white text-base font-sans font-normal ml-11">+91 96991 85758</span>
            </div>
            <div className="flex items-center md:mt-12 mt-10">
              <img src={inbox} alt="Inbox Icon" className="h-7 w-7 ml-10" />
              <span className="text-white text-base font-sans font-normal ml-10">contactus@skilljourney.in</span>
            </div>
            <div className="flex items-center md:mt-7 mt-5">
              <img src={map} alt="Map Icon" className="h-7 w-7 ml-10" />
              <span className="text-white text-sm font-sans font-normal ml-10 mt-8">
                5th Floor, CC&Co, Cornerstone Premises, Prabhat Road, Deccan, Pune 411004
              </span>
            </div>
            <div className="absolute bottom-0  right-0.5">
              <img src={device} alt="Device Icon" className="h-40 w-32" />
            </div>
          </div>
          <div className="w-full  md:w-[500px] h-auto md:h-[150px] p-8 md:-mt-80  md:ml-4">
            <form className="md:mt-5" onSubmit={handleSubmit}>
              <div className="md:mb-3  mb-9 relative">
                <label htmlFor="name" className="block font-sans font-medium text-[14px] leading-[21px] tracking-tight text-gray-400 -mb-1">
                  Name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  className="border-b-2 border-gray-400 pt-7 w-full h-[50px] font-inter font-medium text-[16px] leading-[24px] tracking-tight text-black focus:outline-none focus:border-blue-500 focus:shadow-none"
                />
                {nameError && (
                  <div className="
absolute  left-0 text-red-600 font-sans font-medium text-[14px] 
leading-4 tracking-wide">
                    {nameError}
                  </div>
                )}

              </div>

              <div className="md:mb-3 mb-9 relative">
                <label htmlFor="email" className="block font-sans font-medium text-[14px] leading-[21px] tracking-tight text-gray-400 -mb-1">
                  Email <span className="text-red-600">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  className="border-b-2 border-gray-400 pt-7 w-full h-[50px] font-inter font-medium text-[16px] leading-[24px] tracking-tight text-black focus:outline-none focus:border-blue-500 focus:shadow-none"
                />
                {emailError && (
                  <div className="
absolute  left-0 text-red-600 font-sans font-medium text-[14px] leading-4 tracking-tight">
                    {emailError}
                  </div>
                )}
              </div>
              <div className="md:mb-3 mb-9 relative">
                <label htmlFor="tel" className="block font-sans font-medium text-[14px] leading-[21px] tracking-tight text-gray-400 -mb-1">
                  Contact Number <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="tel"
                  name="tel"
                  value={formValues.tel}
                  onChange={handleChange}
                  className="border-b-2 border-gray-400 pt-7 w-full h-[50px] font-inter font-medium text-[16px] leading-[24px] tracking-tight text-black focus:outline-none focus:border-blue-500 focus:shadow-none"
                />
                {telError && (
                  <div className="
absolute  left-0 text-red-600 font-sans font-medium text-[14px] leading-4 tracking-tight">
                    {telError}
                  </div>
                )}

              </div>

              <div className="md:mb-3 mb-9 relative">
                <label htmlFor="tel" className="block font-sans font-medium text-[14px] leading-[21px] tracking-tight text-gray-400 mb-10">
                  Message <span className="text-red-600">*</span>
                </label>
                <input
                  type="message"
                  id="message"
                  name="message"

                  value={formValues.message}
                  onChange={handleChange}

                  className="border-b-2 border-gray-400 pt-8 w-full h-[50px] font-inter font-medium text-[16px] leading-[24px] tracking-tight text-black focus:outline-none focus:border-blue-500 focus:shadow-none"
                />
                {messageError && (
                  <div className="
absolute  left-0 text-red-600 font-sans font-medium text-[14px] leading-4 tracking-tight">
                    {messageError}
                  </div>
                )}

              </div>

              <div className="flex justify-end md:mt-3 mt-5">
                {error && (
                  <div className="text-red-600 items-center justify-center font-sans font-medium text-[14px] leading-4 tracking-tight md:mr-16 mr-2 py-3">
                    {error}
                  </div>
                )}
                <button
                  type="submit"
                  className="bg-[#0778B6] text-white font-sans font-medium text-base rounded-[8px] md:w-[110px] w-[90px] h-[35px] focus:outline-none flex items-center justify-center"
                >
                  <span className="mr-2">Send</span>
                  <img src={send} alt="logo" className="w-4 h-4" />
                </button>


              </div>
            </form>

          </div>

        </div>
        <div className="flex flex-wrap justify-center mt-[60px] ple-3 font-sans text-sm text-gray-500">

          <Link to="/privacypolicy" className="px-2">Privacy Policy</Link>
          <span className="-px-1">|</span>
          <Link to="/terms&conditions" className="px-2">Terms & Conditions</Link>
          <span className="-px-1">|</span>
          <Link to="/cancellation&refund" className="px-2">Cancellation & Refund</Link>
          <span className="-px-1">|</span>
          <Link to="/shipping" className="px-1">Shipping & Delivery</Link>
        </div>
        <div className="py-3"></div>
      </div>
    </div>
  );
};

export default Contact;
