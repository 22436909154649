import React, { useEffect } from 'react';
import cancellation from "../assets/img/Cancellation.png";
import CreateNavbar from '../components/Navbar/Navbar';

const Cancellation: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="flex overflow-y-auto flex-col">
            <CreateNavbar page='about' />
            <div className="px-10 md:px-14 flex md:justify-center justify-center mt-8 md:mt-4 md:flex text-center">
                <h2 className="font-sans font-bold text-2xl md:text-3xl mb-4 text-black">
                    CANCELLATION AND REFUND
                </h2>
            </div>
            <div className="px-10 md:px-24 flex md:justify-end justify-center mt-8 md:mt-9  md:flex">
                <img src={cancellation} alt="cancellation" className="w-96 h-auto" />
            </div>
            {/* Content Section */}
            <div className="flex flex-col lg:flex-row  mt-8 md:-mt-72">

                {/* Text content */}
                <div className=" text-left px-10 md:px-24">

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color">
                        1. INTRODUCTION
                    </h2>

                    <p className="font-normal text-sm mb-4 font-sans">
                        This Privacy Policy outlines how SkillJourney LLP ("we," "us," <span className="hidden md:inline "><br /></span>"our") collects, uses, and protects your
                        personal information when <span className="hidden md:inline "><br /></span> you use SkillJourney NorthStar, our
                        career guidance platform. By <span className="hidden md:inline "><br /></span> accessing and using our web application,
                        you agree to the terms <span className="hidden md:inline "><br /></span> of this Privacy Policy.
                    </p>

                    {/* Additional content sections */}

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color ">
                        2. COMPANY INFORMATION
                        <ul className=" font-normal font-sans text-sm  list-disc text-black pt-4 pl-8">
                            <li>Company Name: SkillJourney LLP</li>
                            <li>Registered Address: A303, Samrajya Balwantpuram, Shivtirtha Nagar,<span className="hidden md:inline lg:hidden"><br /></span> Paud Road, Pune 411038</li>
                            <li>Current Address: SkillJourney LLP, 5th Floor, CC&Co,<span className="hidden md:inline"><br /></span> CornerStone Premises, Prabhat Road, Pune 411004</li>
                            <li>Contact Email: <a href="mailto:support@skilljourney.in" className="text-highlight-color">support@skilljourney.in</a></li>

                        </ul>
                    </h2>


                    {/* Section 3 */}

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        3. SERVICE TIERS
                        <h3 className="font-semibold text-lg mb-4 text-black font-sans pt-4">Fees
                            <ul className="pt-4 font-normal text-sm font-sans list-disc pl-8">

                                <li>Basic Tier: Free</li>
                                <li>Premium Tier: Rs. 999/- per annum</li>
                            </ul>
                        </h3>
                        <h3 className="font-semibold  text-lg mb-4 font-sans text-black">PREMIUM TIER
                            <p className='font-normal font-sans text-sm text-black pt-4'>The Premium Tier is available for Rs. 999/- per annum.
                            </p>
                        </h3>
                    </h2>

                    {/* Section 4 */}
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        4. CANCELLATION POLICY
                        <h3 className="font-semibold font-sans text-lg mb-4 text-black pt-4">Accurate Information
                            <p className='font-normal font-sans text-sm text-black pt-4'>Users of the Basic Tier can stop using the service at any time without any formal cancellation process.
                            </p>
                        </h3>

                        <h3 className="font-semibold font-sans text-lg mb-4 text-black">Prohibites Actions
                            <p className='font-normal font-sans text-sm text-black pt-4'>Users of the Premium Tier can cancel their subscription at any time by contacting our support team at <a href="mailto:support@skilljourney.in" className="text-highlight-color">support@skilljourney.in</a></p>
                        </h3>
                    </h2>
                    {/* Section 5 */}
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        5. REFUND AND CANCELLATION
                        <p className='font-normal font-sans text-sm text-black pt-4'>Refunds will be issued under the following conditions:</p>
                        <h3 className="font-semibold font-sans text-lg mb-4 text-black pt-4">Eligibility
                            <ul className=" font-normal text-sm  list-disc font-sans pt-4 pl-8">

                                <li>A refund will be issued if SkillJourney NorthStar is unable to generate your career journey plan.
                                </li>
                                <li>Refund requests must be submitted within 30 days of the service failure.
                                </li>

                            </ul>
                        </h3>
                    </h2>


                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        6.  CONTACT INFORMATION
                        <h3 className="font-normal font-sans text-base mb-4 text-black pt-4">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                            <ul className=" font-normal text-sm  list-disc font-sans pl-8">

                                <li>Email: <a href="mailto:support@skilljourney.in" className="text-highlight-color">legal@skilljourney.in</a>
                                </li>

                            </ul>
                        </h3>
                    </h2>



                    <p className='font-normal font-sans text-sm my-4'>By using SkillJourney NorthStar, you acknowledge that you have read, understood, and agree to this Privacy Policy.
                    </p>

                </div>
            </div>
        </div>


    );
}

export default Cancellation;
