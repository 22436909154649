import user from '../../assets/img/premiumJourney/user.png'
import { getQueryParam } from '../CounsellorUser/CounsellorsClientPortFolio/common/util';
import clientIcon from '../../assets/img/placeholder-user.jpg'

function Profile() {
    // Fetch the profile icon from localStorage; provide a fallback if it's null or undefined
    const profileIcon = localStorage.getItem('profileIcon') || user;
    const user_info = JSON.parse(getQueryParam('user_info') as string) || null;

    return (
        <div>
            <div className="custom-width">
                <div className="border border-gray-300 rounded-lg p-3">
                    <div className="flex items-center p-2 border border-gray-200 rounded-md">
                        {/* Image Section */}
                        <div className="flex-shrink-0 mr-3">
                            <img className="w-12 h-12 rounded-full"
                                src={user_info
                                    ? user_info.profileIcon
                                        ? user_info.profileIcon
                                        : clientIcon
                                    : profileIcon}
                                alt="user" />
                        </div>
                        {/* Text Section */}
                        <div className="flex-1 min-w-0">
                            <p className="font-semibold text-gray-800 text-sm truncate">{user_info ? user_info.name ? user_info.name : user_info.email : localStorage.getItem('Name') || 'User'}</p>
                            <p className="text-sm text-gray-500 truncate">Premium Career Journey</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed right-3 w-32 profile-w hidden tab-profile">
                <div className="border border-gray-300 rounded-md p-2">
                    <div className="border border-gray-200 rounded-md">
                        <div className="mt-2 flex justify-center">
                            <img className="w-16 h-16 rounded-full"
                                src={user_info
                                    ? user_info.profileIcon
                                        ? user_info.profileIcon
                                        : clientIcon
                                    : profileIcon || user} alt="user" />
                        </div>
                        <div className="pt-1 text-center">
                            <p className="font-sm text-sm profile-name">{user_info ? user_info.name ? user_info.name : user_info.email  : localStorage.getItem('Name')}</p>
                            <p className="font-normal text-xs text-gray-500">Premium Career Journey</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
