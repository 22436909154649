import React, { useEffect, useState } from 'react';
import CreateNavbar from '../components/Navbar/Navbar';
import { isMobile } from 'react-device-detect';
import PortfolioMobileView from '../components/CounsellorUser/CounsellorsClientPortFolio/Mobile/PortfolioMobileView';
import PortfolioDesktopView from '../components/CounsellorUser/CounsellorsClientPortFolio/Desktop/PortfolioDesktopView';
import { useLocation } from 'react-router-dom';
import { Session } from '../components/CounsellorUser/SessionsDetails/DetailedSessionPage';

const CounsellorsClientPortFolio: React.FC = () => {
    const location = useLocation();
    const session = location.state?.session as Session;
    const [currentSession, setCurrentSession] = useState<Session | null>(null);
    
    useEffect(() => {
      // Scroll to the top of the page
      window.scrollTo(0, 0);
    }, []);
  
    useEffect(() => {
      // Set the current session based on the location state
      setCurrentSession(session || null);
    }, [session]);
    return (
        <div className="relative h-full flex flex-col">
            <CreateNavbar page='home' />
            {isMobile
                ? <PortfolioMobileView currentSession={currentSession} />
                : <PortfolioDesktopView currentSession={currentSession} />
            }
        </div>
    );
};

export default CounsellorsClientPortFolio;
