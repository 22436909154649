import React from 'react';
import Group from "../../assets/img/header/girl 1.svg";
import CreateNavbar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { HowItWorks } from '../common/popup/HowItWorks';

const Header: React.FC = () => {
    const navigate = useNavigate();

    const handleCareerMapClick = () => {
        navigate('/login'); // Adjust the path as necessary
    };


    return (
        <div>
            <CreateNavbar page='header' />
            <div className='relative bg-cover bg-center bg-background'>
                <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-5 bg-opacity-50 md:mx-4'>
                    <div className='container sm:mt-10 lg:mt-27 py-4'>
                        <div className='text-custom-orange text-2xl lg:text-4xl whitespace-nowrap font-bold'>
                            A PERSONALISED ROADMAP
                        </div>
                        <div className='text-sky-600 md:py-0  font-sans text-2xl lg:text-4xl font-bold'>
                            TO YOUR DREAM CAREER
                        </div>
                        <div className='md:text-base text-sm font-sans font-normal text-black md:py-6 py-2 '>
                            Explore how our tailored Pathways with Data-Driven insights can  power your unique  journey towards a successful career
                        </div>
                        <div className="hidden sm:block">
                            <HowItWorks link='gPKGjrQydkk' />
                        </div>
                        <div className='hidden md:flex md:space-x-10'>
                            <button
                                onClick={handleCareerMapClick}
                                className="w-auto py-2.5 my-5 text-mx font-semibold  tracking-wider text-white transition-colors duration-300  transform md:w-56 h-14 px-2 focus:outline-none bg-custom-orange rounded-full"
                            >
                                Create My Career Map
                            </button>
                        </div>
                        <div className="md:hidden grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 space-x-2">
                            <button
                                onClick={handleCareerMapClick}
                                className=" md:mx-1 w-auto py-2.5 my-5 text-xs font-semibold tracking-wider text-white transition-colors duration-300 transform md:w-56 h-14 px-2 focus:outline-none bg-custom-orange rounded-full"
                            >
                                Create My Career Map
                            </button>
                            <button
                                className="flex items-center text-black text-base font-semibold font-sans w-auto whitespace-nowrap"
                            > 
                                <HowItWorks link='gPKGjrQydkk' />
                            </button>
                        </div>
                    </div>
                    <div className='relative md:ml-10'>
                        <img src={Group} alt="" className='top-0 left-0 md:w-full md:h-[460px]  w-auto z-0' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
