import { BackendApi } from './apiConfig/ApiFormatter';


export const addRating = async (
  ratingData : any
): Promise<any> => {
  const apiUrl = BackendApi() + 'create_rating';
  
  try {
      const response = await fetch(apiUrl, {
          method: 'POST', // HTTP method
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(ratingData),
      });
      
      // Check if the response is ok (status code in the range 200-299)
      
      if (response.ok) {
          console.log('Rating submitted successfully');
      } else {
          console.error('Failed to submit rating');
      }

      
      return response;        
  } catch (error) {
    console.error('Error submitting rating:', error);
      throw error;  // rethrow the error
  }
};