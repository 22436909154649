import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"; 
import { Container, Accordion } from 'react-bootstrap';
import CreateNavbar from "../components/Navbar/Navbar";
import data from '../components/common/opportunities/Opportunities.json';
import Technology from '../assets/img/opportunities_img/Technology.jpg';
import Business from '../assets/img/opportunities_img/business_and_management.jpg';
import Arts from '../assets/img/opportunities_img/creative_art.jpg';
import Health from '../assets/img/opportunities_img/health_and_medicine.jpg';
import Engineering from '../assets/img/opportunities_img/engineering.jpg';
import Education from '../assets/img/opportunities_img/education_and_teaching.jpg';
import Law from '../assets/img/opportunities_img/law_and_legal.jpg';
import Finance from '../assets/img/opportunities_img/finance_and_accoutning.jpg';
import Human from '../assets/img/opportunities_img/human_resources.jpg';
import Sales from '../assets/img/opportunities_img/sales_and_marketing.jpg';
import DataScience from '../assets/img/opportunities_img/data science_and_anayltics.jpg';
import Entrepreneurship from '../assets/img/opportunities_img/enterpreneur_and_startup.jpg';
import CreativeArts from '../assets/img/opportunities_img/creative_art.jpg';
import Government from '../assets/img/opportunities_img/government_and_public.jpg';
import Enviroment from '../assets/img/opportunities_img/environment_and_agriculture.jpg';
import Communication from '../assets/img/opportunities_img/communication_and_media.jpg';
import Sport from '../assets/img/opportunities_img/sports_and_recreation.jpg';

const imageMapping: { [key: number]: string } = {
  0: Technology, 1: Business, 2: Arts, 3: Health, 4: Engineering, 5: Education, 6: Law, 7: Finance, 8: Human, 9: Sales, 10: DataScience, 11: Entrepreneurship, 12: CreativeArts, 13: Government, 14: Enviroment, 15: Communication, 16: Sport
};

interface FieldData {
  "Opportunities and Roles": string[];
  Resources: {
    "Educational Resources": { title: string; url: string }[];
    "Online Courses": { title: string; url: string }[];
    "Industry Blogs": { title: string; url: string }[];
    "Professional Networks": { title: string; url: string }[];
  };
  Insights: string;
}

const isFieldData = (field: any): field is FieldData => {
  return (
    typeof field === 'object' &&
    'Opportunities and Roles' in field &&
    'Resources' in field &&
    'Insights' in field
  );
};

const Explore: React.FC = () => {
  const careerData = data["Career Development"];
  const sectionRefs = useRef<{ [key: number]: React.RefObject<HTMLDivElement> }>({});
  const navigate = useNavigate();

  useEffect(() => {
      // Scroll to the top of the page
      window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    const index = parseInt(hash);
    if (!isNaN(index) && sectionRefs.current[index]) {
      sectionRefs.current[index].current?.scrollIntoView({ behavior: 'smooth' });
    }

    const handlePopState = () => {
      if (!window.location.hash) {
        navigate("/"); 
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);


  return (
    <div className="flex overflow-y-auto flex-col ">
      <CreateNavbar page="about" />
      <Container fluid>
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center text-[#0778B6] my-6">
          Explore Opportunities
        </h1>
        <div className="space-y-4 px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          <Accordion defaultActiveKey="0" className="p-4">
            {Object.entries(careerData).map(([category, fields], categoryIndex) => {
              const imgPath = imageMapping[categoryIndex];
              const filteredFields = Object.entries(fields).filter(([key]) => key !== 'imgPath');

              sectionRefs.current[categoryIndex] = sectionRefs.current[categoryIndex] || React.createRef();

              return (
                <Accordion.Item 
                  eventKey={categoryIndex.toString()} 
                  key={category} 
                  className="mb-6"
                  ref={sectionRefs.current[categoryIndex]}
                  id={categoryIndex.toString()}
                >
                  <Accordion.Header className="p-4">
                    <span className="text-xl md:text-2xl font-bold bg-custom-gradient text-transparent bg-clip-text">
                      {categoryIndex + 1}. {category.toUpperCase()}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body className="p-4 md:p-6 font-sans">
                    {imgPath && (
                      <div className="flex justify-start mb-8">
                        <img src={imgPath} alt={category} className="w-full max-w-2xl mx-auto" />
                      </div>
                    )}
                    {filteredFields.map(([field, fieldData], fieldIndex) => {
                      if (isFieldData(fieldData)) {
                        return (
                          <div key={field} className="mb-4 last:mb-0">
                            <ul className="list-disc pl-0 space-y-2">
                              <h4 className="text-2xl md:text-2xl font-bold mb-4 text-[#0778B6]">
                                {field}
                              </h4>
                            </ul>
                            <ul className="space-y-2">
                              <li>
                                <span className="font-semibold">Opportunities and Roles:</span>{' '}
                                {fieldData["Opportunities and Roles"].join(', ')}
                              </li>
                              <li>
                                <span className="font-semibold">Resources:</span>
                                <ul className="list-disc pl-6 space-y-1 mt-1">
                                  {Object.entries(fieldData.Resources).map(([resourceType, resources]) => (
                                    <li key={resourceType}>
                                      <span className="">{resourceType}:</span>{' '}
                                      {resources.map((resource, index) => (
                                        <React.Fragment key={index}>
                                          <a href={resource.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                            {resource.title}
                                          </a>
                                          {index < resources.length - 1 && ', '}
                                        </React.Fragment>
                                      ))}
                                    </li>
                                  ))}
                                </ul>
                              </li>
                              <li>
                                <span className="font-semibold">Insights:</span> {fieldData.Insights}
                              </li>
                            </ul>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </Container>
    </div>
  );
};

export default Explore;